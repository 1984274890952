/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateRoleRequest } from '../models/TCreateRoleRequest';
import type { TCreateRoleResponse } from '../models/TCreateRoleResponse';
import type { TGetRoleResponse } from '../models/TGetRoleResponse';
import type { TListRolesResponse } from '../models/TListRolesResponse';
import type { TRoleId } from '../models/TRoleId';
import type { TUpdateRoleRequest } from '../models/TUpdateRoleRequest';
import type { TUpdateRoleResponse } from '../models/TUpdateRoleResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleService {

    /**
     * Get Role
     * @param id
     * @param relations
     * @returns TGetRoleResponse TGetRoleResponse
     * @throws ApiError
     */
    public static getRole(
        id: TRoleId,
        relations?: string,
    ): CancelablePromise<TGetRoleResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Role
     * @param id
     * @param requestBody
     * @returns TUpdateRoleResponse TUpdateRoleResponse
     * @throws ApiError
     */
    public static updateRole(
        id: TRoleId,
        requestBody: TUpdateRoleRequest,
    ): CancelablePromise<TUpdateRoleResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Role
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteRole(
        id: TRoleId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Roles
     * @param scriptId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListRolesResponse TListRolesResponse
     * @throws ApiError
     */
    public static listRoles(
        scriptId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListRolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
            query: {
                'scriptId': scriptId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Role
     * @param requestBody
     * @returns TCreateRoleResponse TCreateRoleResponse
     * @throws ApiError
     */
    public static createRole(
        requestBody: TCreateRoleRequest,
    ): CancelablePromise<TCreateRoleResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/roles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
