/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Fee Type
 */
export enum FeeType {
    MEAL_PERIOD_VIOLATION = 'Meal Period Violation',
    UP_TO_25_DAYS_PER_DAY_ = 'Up to 25 Days (Per Day)',
    MAXIMUM = 'Maximum',
    ON_NON_NAYMENT_NOTICE = 'On Non-Nayment Notice',
    EACH_DAY_AFTER_NON_PAYMENT_NOTICE_25_DAYS = 'Each Day After Non-Payment Notice ≧ 25 days',
}
