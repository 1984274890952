/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Validation Token Types
 */
export enum ValidationTokenType {
    CONFIRMATION = 'confirmation',
    RESET_PASSWORD = 'reset-password',
    GROUP_INVITATION = 'group-invitation',
}
