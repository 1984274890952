/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TAllowanceId } from '../models/TAllowanceId';
import type { TCreateAllowanceRequest } from '../models/TCreateAllowanceRequest';
import type { TCreateAllowanceResponse } from '../models/TCreateAllowanceResponse';
import type { TGetAllowanceResponse } from '../models/TGetAllowanceResponse';
import type { TListAllowancesResponse } from '../models/TListAllowancesResponse';
import type { TUpdateAllowanceRequest } from '../models/TUpdateAllowanceRequest';
import type { TUpdateAllowanceResponse } from '../models/TUpdateAllowanceResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AllowanceService {

    /**
     * Get Allowance
     * @param id
     * @param relations
     * @returns TGetAllowanceResponse TGetAllowanceResponse
     * @throws ApiError
     */
    public static getAllowance(
        id: TAllowanceId,
        relations?: string,
    ): CancelablePromise<TGetAllowanceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/allowances/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Allowance
     * @param id
     * @param requestBody
     * @returns TUpdateAllowanceResponse TUpdateAllowanceResponse
     * @throws ApiError
     */
    public static updateAllowance(
        id: TAllowanceId,
        requestBody: TUpdateAllowanceRequest,
    ): CancelablePromise<TUpdateAllowanceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/allowances/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Allowance
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteAllowance(
        id: TAllowanceId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/allowances/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Allowances
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListAllowancesResponse TListAllowancesResponse
     * @throws ApiError
     */
    public static listAllowances(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListAllowancesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/allowances',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Allowance
     * @param requestBody
     * @returns TCreateAllowanceResponse TCreateAllowanceResponse
     * @throws ApiError
     */
    public static createAllowance(
        requestBody: TCreateAllowanceRequest,
    ): CancelablePromise<TCreateAllowanceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/allowances',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
