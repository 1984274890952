/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateDocumentResponse } from '../models/TCreateDocumentResponse';
import type { TDocumentId } from '../models/TDocumentId';
import type { TGetDocumentResponse } from '../models/TGetDocumentResponse';
import type { TListDocumentsResponse } from '../models/TListDocumentsResponse';
import type { TUpdateDocumentRequest } from '../models/TUpdateDocumentRequest';
import type { TUpdateDocumentResponse } from '../models/TUpdateDocumentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * Get Document
     * @param id
     * @param relations
     * @returns TGetDocumentResponse TGetDocumentResponse
     * @throws ApiError
     */
    public static getDocument(
        id: TDocumentId,
        relations?: string,
    ): CancelablePromise<TGetDocumentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documents/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Document
     * @param id
     * @param requestBody
     * @returns TUpdateDocumentResponse TUpdateDocumentResponse
     * @throws ApiError
     */
    public static updateDocument(
        id: TDocumentId,
        requestBody: TUpdateDocumentRequest,
    ): CancelablePromise<TUpdateDocumentResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Document
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteDocument(
        id: TDocumentId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/documents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Documents
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListDocumentsResponse TListDocumentsResponse
     * @throws ApiError
     */
    public static listDocuments(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documents',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Document
     * @param formData
     * @returns TCreateDocumentResponse TCreateDocumentResponse
     * @throws ApiError
     */
    public static createDocument(
        formData: {
            type: string;
            project_id: string;
            name: string;
            file: Blob;
        },
    ): CancelablePromise<TCreateDocumentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/documents',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get Signed Url for Document File
     * @param id
     * @returns string Ok
     * @throws ApiError
     */
    public static getSignedUrl(
        id: TDocumentId,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documents/signed-url/{id}',
            path: {
                'id': id,
            },
        });
    }

}
