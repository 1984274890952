/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreatePricingTierRequest } from '../models/TCreatePricingTierRequest';
import type { TCreatePricingTierResponse } from '../models/TCreatePricingTierResponse';
import type { TGetPricingTierResponse } from '../models/TGetPricingTierResponse';
import type { TListPricingTiersResponse } from '../models/TListPricingTiersResponse';
import type { TPricingTierId } from '../models/TPricingTierId';
import type { TUpdatePricingTierRequest } from '../models/TUpdatePricingTierRequest';
import type { TUpdatePricingTierResponse } from '../models/TUpdatePricingTierResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PricingTierService {

    /**
     * Get Pricing Tier
     * @param id
     * @param relations
     * @returns TGetPricingTierResponse TGetPricingTierResponse
     * @throws ApiError
     */
    public static getPricingTier(
        id: TPricingTierId,
        relations?: string,
    ): CancelablePromise<TGetPricingTierResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pricing-tiers/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Pricing Tier
     * @param id
     * @param requestBody
     * @returns TUpdatePricingTierResponse TUpdatePricingTierResponse
     * @throws ApiError
     */
    public static updatePricingTier(
        id: TPricingTierId,
        requestBody: TUpdatePricingTierRequest,
    ): CancelablePromise<TUpdatePricingTierResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pricing-tiers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Pricing Tier
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deletePricingTier(
        id: TPricingTierId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pricing-tiers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Pricing Tiers
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListPricingTiersResponse TListPricingTiersResponse
     * @throws ApiError
     */
    public static listPricingTiers(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListPricingTiersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pricing-tiers',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Pricing Tier
     * @param requestBody
     * @returns TCreatePricingTierResponse TCreatePricingTierResponse
     * @throws ApiError
     */
    public static createPricingTier(
        requestBody: TCreatePricingTierRequest,
    ): CancelablePromise<TCreatePricingTierResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pricing-tiers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
