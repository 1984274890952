/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreatePropRequest } from '../models/TCreatePropRequest';
import type { TCreatePropResponse } from '../models/TCreatePropResponse';
import type { TGetPropResponse } from '../models/TGetPropResponse';
import type { TListPropsResponse } from '../models/TListPropsResponse';
import type { TPropId } from '../models/TPropId';
import type { TUpdatePropRequest } from '../models/TUpdatePropRequest';
import type { TUpdatePropResponse } from '../models/TUpdatePropResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropService {

    /**
     * Get Prop
     * @param id
     * @param relations
     * @returns TGetPropResponse TGetPropResponse
     * @throws ApiError
     */
    public static getProp(
        id: TPropId,
        relations?: string,
    ): CancelablePromise<TGetPropResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/props/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Prop
     * @param id
     * @param requestBody
     * @returns TUpdatePropResponse TUpdatePropResponse
     * @throws ApiError
     */
    public static updateProp(
        id: TPropId,
        requestBody: TUpdatePropRequest,
    ): CancelablePromise<TUpdatePropResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/props/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Prop
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteProp(
        id: TPropId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/props/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Props
     * @param scriptId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListPropsResponse TListPropsResponse
     * @throws ApiError
     */
    public static listProps(
        scriptId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListPropsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/props',
            query: {
                'scriptId': scriptId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Prop
     * @param requestBody
     * @returns TCreatePropResponse TCreatePropResponse
     * @throws ApiError
     */
    public static createProp(
        requestBody: TCreatePropRequest,
    ): CancelablePromise<TCreatePropResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/props',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
