/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Performer Types
 */
export enum PerformerType {
    ON_CAMERA_PRINCIPAL = 'On Camera Principal',
    ON_CAMERA_GROUP = 'On Camera Group',
    OFF_CAMERA_PRINCIPAL = 'Off Camera Principal',
    OFF_CAMERA_GROUP = 'Off Camera Group',
    EXTRA = 'Extra',
    AMERICAN_FEDERATION_OF_MUSICIANS = 'American Federation Of Musicians',
    ON_CAMERA_GROUP_CONTRACTOR_1_2_ = 'On Camera Group Contractor (1-2)',
    ON_CAMERA_GROUP_CONTRACTOR_3_5_ = 'On Camera Group Contractor (3-5)',
    ON_CAMERA_GROUP_CONTRACTOR_6_8_ = 'On Camera Group Contractor (6-8)',
    ON_CAMERA_GROUP_CONTRACTOR_9_ = 'On Camera Group Contractor (9+)',
    OFF_CAMERA_GROUP_CONTRACTOR_1_2_ = 'Off Camera Group Contractor (1-2)',
    OFF_CAMERA_GROUP_CONTRACTOR_3_5_ = 'Off Camera Group Contractor (3-5)',
    OFF_CAMERA_GROUP_CONTRACTOR_6_8_ = 'Off Camera Group Contractor (6-8)',
    OFF_CAMERA_GROUP_CONTRACTOR_9_ = 'Off Camera Group Contractor (9+)',
}
