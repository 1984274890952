/* eslint-disable @typescript-eslint/no-explicit-any */
import { Accordion, AccordionPanel, Box, CheckBox, FormField, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { EditableText, Selector, TalonCard } from '.';

export interface IPanelType {
  label: string;
  labelPlural?: string;
  key: string;
  values: string[];
  filterKey?: string;
  filterFunction?: (value: string) => string[];
  clear?: boolean;
  readonly?: boolean;
}

export interface IItem {
  id: string;
  name: string;
  [key: string]: any;
}

export interface IPanelProps extends React.PropsWithChildren<any> {
  items: IItem[];
  newItem: () => void;
  deleteItems: (ids: string[]) => Promise<void>;
  updateItem: (update: any, index: number) => void;
  label: string;
  labelPlural?: string;
  addIcon?: JSX.Element;
  types: IPanelType[];
  buttons?: React.ReactNode[];
}

export const Panel = observer(
  ({ items, newItem, deleteItems, updateItem, label, labelPlural, addIcon, types, buttons, children }: IPanelProps) => {
    const [selected, setSelected] = useState<string[]>([]);
    useEffect(() => {
      setSelected([]);
    }, [items]);

    const choose = (e: React.ChangeEvent, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      console.log('CHOOSE');
      if (selected.includes(id)) {
        setSelected(selected.filter((s) => s !== id));
      } else {
        setSelected([...selected, id]);
      }
    };

    // const takeFocus = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    // };
    const deleteSelected = async () => {
      await deleteItems(selected);
      setSelected([]);
    };
    const selectedNames = () => {
      return selected.map((s) => items.find((item) => item.id === s)?.name).join(', ');
    };

    return (
      <TalonCard
        newItem={newItem}
        deleteItems={deleteSelected}
        label={label}
        labelPlural={labelPlural}
        addIcon={addIcon}
        buttons={buttons}
        selectedNames={selectedNames()}
      >
        <Accordion overflow="auto">
          {items.length &&
            items.map((item, i) => (
              <AccordionPanel
                label={
                  <Box direction="row" pad="small" width="100%">
                    <CheckBox
                      id={`choose-${item.name.toLowerCase()}`}
                      // onClick={(e) => takeFocus(e)}
                      checked={selected.includes(item.id)}
                      onChange={(e) => choose(e, item.id)}
                      pad={{ horizontal: 'small' }}
                    />
                    <EditableText val={item.name} onChange={(name) => updateItem({ ...item, name }, i)}></EditableText>
                  </Box>
                }
                key={item.id}
              >
                {types.map((t) =>
                  t.readonly ? (
                    t.label &&
                    item[t.key] && (
                      <Text margin="small" key={t.label}>
                        {t.label}: {item[t.key]}
                      </Text>
                    )
                  ) : (
                    <Box direction="column" justify="between" pad="small" key={t.key}>
                      <FormField label={`${t.label}`}>
                        <Selector
                          placehholder={`${t.label}`}
                          options={(t.filterFunction && t.filterKey
                            ? t.filterFunction(item[t.filterKey])
                            : t.values
                          ).map((type) => ({ id: type, name: type }))}
                          value={item[t.key]}
                          onChange={(type) => updateItem({ ...item, [t.key]: type }, i)}
                          size="small"
                          clear={t.clear || false}
                        />
                      </FormField>
                    </Box>
                  )
                )}
                {children && children[i]}
              </AccordionPanel>
            ))}
        </Accordion>
      </TalonCard>
    );
  }
);
