/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Studio Zones
 */
export enum StudioZone {
    LOS_ANGELES = 'Los Angeles',
    NEW_YORK_CITY = 'New York City',
}
