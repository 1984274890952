/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateShootRequest } from '../models/TCreateShootRequest';
import type { TCreateShootResponse } from '../models/TCreateShootResponse';
import type { TGetShootResponse } from '../models/TGetShootResponse';
import type { TListShootsResponse } from '../models/TListShootsResponse';
import type { TProjectId } from '../models/TProjectId';
import type { TShootId } from '../models/TShootId';
import type { TUpdateShootRequest } from '../models/TUpdateShootRequest';
import type { TUpdateShootResponse } from '../models/TUpdateShootResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShootService {

    /**
     * Get Shoot
     * @param id
     * @param relations
     * @returns TGetShootResponse TGetShootResponse
     * @throws ApiError
     */
    public static getShoot(
        id: TShootId,
        relations?: string,
    ): CancelablePromise<TGetShootResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/shoots/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Shoot
     * @param id
     * @param requestBody
     * @returns TUpdateShootResponse TUpdateShootResponse
     * @throws ApiError
     */
    public static updateShoot(
        id: TShootId,
        requestBody: TUpdateShootRequest,
    ): CancelablePromise<TUpdateShootResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/shoots/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Shoot
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteShoot(
        id: TShootId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/shoots/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Shoots
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListShootsResponse TListShootsResponse
     * @throws ApiError
     */
    public static listShoots(
        projectId: TProjectId,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListShootsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/shoots',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Shoot
     * @param requestBody
     * @returns TCreateShootResponse TCreateShootResponse
     * @throws ApiError
     */
    public static createShoot(
        requestBody: TCreateShootRequest,
    ): CancelablePromise<TCreateShootResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/shoots',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
