/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Usage Types
 */
export enum UsageType {
    TV_LINEAR_DOMESTIC_NETWORK_CLASS_A_USE_1_TO_3_ = 'TV Linear Domestic Network Class A (Use 1 To 3)',
    TV_LINEAR_DOMESTIC_NETWORK_CLASS_A_USE_4_TO_13_ = 'TV Linear Domestic Network Class A (Use 4 To 13)',
    TV_LINEAR_DOMESTIC_NETWORK_CLASS_A_USE_14_PLUS_ = 'TV Linear Domestic Network Class A (Use 14 Plus)',
    TV_LINEAR_DOMESTIC_NETWORK_CLASS_A_13_WEEKS_MAXIMUM_ = 'TV LinearDomestic Network Class A (13 Weeks Maximum)',
    TV_LINEAR_DOMESTIC_ION_AND_DIGINET_PER_USE = 'TV Linear Domestic ION And Diginet Per Use',
    TV_LINEAR_DOMESTIC_ION_AND_DIGINET_13_WEEKS_UNLIMITED_USE_ = 'TV Linear Domestic ION And Diginet (13 Weeks Unlimited Use)',
    TV_SPANISH_LANGUAGE_PROGRAM_4_WEEK_ = 'TV Spanish Language Program (4 Week)',
    TV_SPANISH_LANGUAGE_PROGRAM_13_WEEK_ = 'TV Spanish Language Program (13 Week)',
    TV_SPANISH_LANGUAGE_PROGRAM_52_WEEK_ = 'TV Spanish Language Program (52 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_ALL_MARKETS_4_WEEK_ = 'TV Linear Domestic Wild Spot All Markets (4 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_ALL_MARKETS_13_WEEK_ = 'TV Linear Domestic Wild Spot All Markets (13 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_AL_L_MARKETS_52_WEEK_ = 'TV Linear Domestic Wild Spot Al lMarkets (52 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_ALL_MARKETS_NO_NY_LA_4_WEEK_ = 'TV Linear Domestic Wild Spot All Markets (No NY/LA - 4 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_ALL_MARKETS_NO_NY_LA_13_WEEK_ = 'TV Linear Domestic Wild Spot All Markets (No NY/LA - 13 Week)',
    TV_LINEAR_DOMESTIC_WILD_SPOT_ALL_MARKETS_NO_NY_LA_52_WEEK_ = 'TV Linear Domestic Wild Spot All Markets (No NY/LA - 52 Week)',
    TV_LINEAR_DOMESTIC_NATIONAL_CABLE_4_WEEK_ = 'TV Linear Domestic National Cable (4 Week)',
    TV_LINEAR_DOMESTIC_NATIONAL_CABLE_13_WEEK_ = 'TV Linear Domestic National Cable (13 Week)',
    TV_LINEAR_DOMESTIC_NATIONAL_CABLE_52_WEEK_ = 'TV Linear Domestic National Cable (52 Week)',
    TV_LINEAR_DOMESTIC_LOCAL_CABLE_4_WEEK_ = 'TV Linear Domestic Local Cable (4 Week)',
    TV_LINEAR_DOMESTIC_LOCAL_CABLE_13_WEEK_ = 'TV Linear Domestic Local Cable (13 Week)',
    TV_LINEAR_DOMESTIC_LOCAL_CABLE_52_WEEK_ = 'TV Linear Domestic Local Cable (52 Week)',
    TV_LINEAR_DOMESTIC_DEALER_4_WEEK_ = 'TV Linear Domestic Dealer (4 Week)',
    TV_LINEAR_DOMESTIC_DEALER_13_WEEK_ = 'TV Linear Domestic Dealer (13 Week)',
    TV_LINEAR_DOMESTIC_DEALER_52_WEEK_ = 'TV Linear Domestic Dealer (52 Week)',
    TV_LINEAR_DOMESTIC_ALL_OTHER_NA_LINEAR_4_WEEK_ = 'TV Linear Domestic All Other NA Linear (4 Week)',
    TV_LINEAR_DOMESTIC_ALL_OTHER_NA_LINEAR_13_WEEK_ = 'TV Linear Domestic All Other NA Linear (13 Week)',
    TV_LINEAR_DOMESTIC_ALL_OTHER_NA_LINEAR_52_WEEK_ = 'TV Linear Domestic All Other NA Linear (52 Week)',
    TV_THEATRICAL_INDUSTRIAL_UP_TO_30_DAYS_ = 'TV Theatrical Industrial (Up To 30 Days)',
    TV_THEATRICAL_INDUSTRIAL_USE_THROUGH_MPU_ = 'TV Theatrical Industrial (Use Through MPU)',
    TV_THEATRICAL_INDUSTRIAL_DIST_ON_PHYSICAL_MEDIA_ = 'TV Theatrical Industrial (Dist On Physical Media)',
    TV_DIGITAL_TRADITIONAL_DIGITAL_4_WEEK_ = 'TV Digital Traditional Digital (4 Week)',
    TV_DIGITAL_TRADITIONAL_DIGITAL_13_WEEK_ = 'TV Digital Traditional Digital (13 Week)',
    TV_DIGITAL_TRADITIONAL_DIGITAL_52_WEEK_ = 'TV Digital Traditional Digital (52 Week)',
    TV_DIGITAL_STREAMING_PLATFORMS_4_WEEK_ = 'TV Digital Streaming Platforms (4 Week)',
    TV_DIGITAL_STREAMING_PLATFORMS_13_WEEK_ = 'TV Digital Streaming Platforms (13 Week)',
    TV_DIGITAL_STREAMING_PLATFORMS_52_WEEK_ = 'TV Digital Streaming Platforms (52 Week)',
    TV_DIGITAL_SOCIAL_MEDIA_30_DAY_CYCLE_ = 'TV Digital Social Media (30 Day Cycle)',
    TV_DIGITAL_SOCIAL_MEDIA_YOU_TUBE = 'TV Digital Social Media, YouTube',
    TV_DIGITAL_GAMING_VR_AR_EMERGING_PLATFORMS = 'TV Digital Gaming VR/AR, Emerging Platforms',
    TV_FOREIGN_USE_SPANISH_LANGUAGE_AMERICAS_ = 'TV Foreign Use - Spanish Language (Americas)',
    TV_FOREIGN_USE_SPANISH_LANGUAGE_CARIBBEAN_ = 'TV Foreign Use - Spanish Language (Caribbean)',
    TV_FOREIGN_USE_SPANISH_LANGUAGE_AMERICAS_AND_CARIBBEAN_ = 'TV Foreign Use - Spanish Language (Americas And Caribbean)',
    TV_FOREIGN_USE_OTHER_UK_ = 'TV Foreign Use - Other (UK)',
    TV_FOREIGN_USE_OTHER_JAPAN_ = 'TV Foreign Use - Other (Japan)',
    TV_FOREIGN_USE_OTHER_EUROPE_ = 'TV Foreign Use - Other (Europe)',
    TV_FOREIGN_USE_OTHER_REST_OF_WORLD_ = 'TV Foreign Use - Other (Rest Of World)',
    TV_FOREIGN_USE_OTHER_ASIA_PACIFIC_ = 'TV Foreign Use - Other (Asia Pacific)',
    TV_FOREIGN_USE_OTHER_WORLDWIDE_ = 'TV Foreign Use - Other (Worldwide)',
    TV_AFM_FIRST_USE_INTERNET_ONLY_OR_US_ = 'TV AFM First Use (Internet Only Or US)',
    TV_AFM_FOREIGN_1_YEAR_EUROPE_NO_EUROPE_ = 'TV AFM Foreign - 1 Year (Europe/No Europe)',
    TV_AFM_FOREIGN_WORLDWIDE_ = 'TV AFM Foreign (Worldwide)',
    TV_AFM_RADIO_SPOT_INTERNET_8_WEEK_ = 'TV AFM Radio Spot - Internet (8 Week)',
    TV_AFM_RADIO_SPOT_INTERNET_26_WEEK_ = 'TV AFM Radio Spot - Internet (26 Week)',
    TV_AFM_RADIO_SPOT_INTERNET_52_WEEK_ = 'TV AFM Radio Spot - Internet (52 Week)',
    TV_HOLDING_FEE_2016_RATES_ = 'TV Holding Fee (2016 rates)',
    TV_LIFT_FEE_2016_RATES_ = 'TV Lift Fee (2016 rates)',
    TV_NETWORK_CLASS_A_2016_RATES_ = 'TV Network Class A (2016 rates)',
    TV_NETWORK_CLASS_B_WITH_NY_2016_RATES_ = 'TV Network Class B With NY (2016 rates)',
    TV_NETWORK_CLASS_B_WITHOUT_NY_2016_RATES_ = 'TV Network Class B Without NY (2016 rates)',
    TV_NETWORK_CLASS_C_2016_RATES_ = 'TV Network Class C (2016 rates)',
    TV_UNWIRED_13_WEEK_2016_RATES_ = 'TV Unwired 13 Week (2016 rates)',
    TV_DIGINETS_2016_RATES_ = 'TV Diginets (2016 rates)',
    TV_CABLE_2016_RATES_ = 'TV Cable (2016 rates)',
    TV_CABLE_MAX_2016_RATES_ = 'TV Cable Max (2016 rates)',
    TV_LOCAL_CABLE_2016_RATES_ = 'TV Local Cable (2016 rates)',
    TV_WILD_SPOT_NO_MAJOR_MARKETS_2016_RATES_ = 'TV Wild Spot - No Major Markets (2016 rates)',
    TV_WILD_SPOT_NY_ONLY_2016_RATES_ = 'TV Wild Spot - NY Only (2016 rates)',
    TV_WILD_SPOT_LA_OR_CHICAGO_ONLY_2016_RATES_ = 'TV Wild Spot - LA Or Chicago Only (2016 rates)',
    TV_WILD_SPOT_2_OF_LA_NY_CHICAGO_2016_RATES_ = 'TV Wild Spot - 2 Of LA, NY, Chicago (2016 rates)',
    TV_WILD_SPOT_LA_NY_AND_CHICAGO_2016_RATES_ = 'TV Wild Spot - LA, NY, And Chicago (2016 rates)',
    TV_INTERNET_NEW_MEDIA_4_WEEK_2016_RATES_ = 'TV Internet New Media - 4 Week (2016 rates)',
    TV_INTERNET_NEW_MEDIA_8_WEEK_MOVE_OVER_2016_RATES_ = 'TV Internet New Media - 8 Week Move Over (2016 rates)',
    TV_INTERNET_NEW_MEDIA_8_WEEK_MADE_FOR_2016_RATES_ = 'TV Internet New Media - 8 Week Made For (2016 rates)',
    TV_INTERNET_NEW_MEDIA_1_YEAR_MOVE_OVER_2016_RATES_ = 'TV Internet New Media - 1 Year Move Over (2016 rates)',
    TV_INTERNET_NEW_MEDIA_1_YEAR_MADE_FOR_2016_RATES_ = 'TV Internet New Media - 1 Year Made For (2016 rates)',
    TV_INDUSTRIAL_30_DAYS_2016_RATES_ = 'TV Industrial - 30 Days (2016 rates)',
    TV_INDUSTRIAL_DAY_31_MPU_2016_RATES_ = 'TV Industrial - Day 31-MPU (2016 rates)',
    TV_DEALER_TYPE_A_6_MONTHS_WITH_NY_2016_RATES_ = 'TV Dealer Type A - 6 Months With NY (2016 rates)',
    TV_DEALER_TYPE_A_6_MONTHS_WITHOUT_NY_2016_RATES_ = 'TV Dealer Type A - 6 Months Without NY (2016 rates)',
    TV_DEALER_TYPE_B_6_MONTHS_WITH_NY_2016_RATES_ = 'TV Dealer Type B - 6 Months With NY (2016 rates)',
    TV_DEALER_TYPE_B_6_MONTHS_WITHOUT_NY_2016_RATES_ = 'TV Dealer Type B - 6 Months Without NY (2016 rates)',
    TV_DEALER_TYPE_A_8_WEEK_WITH_NY_2016_RATES_ = 'TV Dealer Type A - 8 Week With NY (2016 rates)',
    TV_DEALER_TYPE_A_8_WEEK_WITHOUT_NY_2016_RATES_ = 'TV Dealer Type A - 8 Week Without NY (2016 rates)',
    TV_DEALER_TYPE_B_8_WEEK_WITH_NY_2016_RATES_ = 'TV Dealer Type B - 8 Week With NY (2016 rates)',
    TV_DEALER_TYPE_B_8_WEEK_WITHOUT_NY_2016_RATES_ = 'TV Dealer Type B - 8 Week Without NY (2016 rates)',
    RADIO_NETWORK_1_WEEK_UNLIMITED_USE = 'Radio Network - 1 Week Unlimited Use',
    RADIO_NETWORK_4_WEEK_UNLIMITED_USE = 'Radio Network - 4 Week Unlimited Use',
    RADIO_NETWORK_8_WEEK_UNLIMITED_USE = 'Radio Network - 8 Week Unlimited Use',
    RADIO_NETWORK_13_WEEK_UNLIMITED_USE = 'Radio Network - 13 Week Unlimited Use',
    RADIO_NETWORK_13_WEEKS_26_USES = 'Radio Network - 13 Weeks 26 Uses',
    RADIO_NETWORK_13_WEEKS_39_USES = 'Radio Network - 13 Weeks 39 Uses',
    RADIO_NETWORK_13_WEEKS_ACROSS_THE_BOARD_USE = 'Radio Network - 13 Weeks Across The Board Use',
    RADIO_WILD_SPOT_8_WEEK_NO_MAJOR_MARKETS_ = 'Radio Wild Spot - 8 Week (No Major Markets)',
    RADIO_WILD_SPOT_8_WEEK_NY_ONLY_ = 'Radio Wild Spot - 8 Week (NY Only)',
    RADIO_WILD_SPOT_8_WEEK_LA_OR_CHICAGO_ONLY_ = 'Radio Wild Spot - 8 Week (LA Or Chicago Only)',
    RADIO_WILD_SPOT_8_WEEK_2_OF_LA_NY_CHICAGO_ = 'Radio Wild Spot - 8 Week (2 Of LA, NY, Chicago)',
    RADIO_WILD_SPOT_8_WEEK_LA_NY_AND_CHICAGO_ = 'Radio WildSpot - 8 Week (LA, NY, And Chicago)',
    RADIO_WILD_SPOT_13_WEEKS_NO_MAJOR_MARKETS_ = 'Radio Wild Spot - 13 Weeks (No Major Markets)',
    RADIO_WILD_SPOT_13_WEEKS_NY_ONLY_ = 'Radio Wild Spot - 13 Weeks (NY Only)',
    RADIO_WILD_SPOT_13_WEEKS_LA_OR_CHICAGO_ONLY_ = 'Radio Wild Spot - 13 Weeks (LA Or Chicago Only)',
    RADIO_WILD_SPOT_13_WEEKS_2_OF_LA_NY_CHICAGO_ = 'Radio Wild Spot - 13 Weeks (2 Of LA, NY, Chicago)',
    RADIO_WILD_SPOT_13_WEEKS_LA_NY_AND_CHICAGO_ = 'Radio WildSpot - 13 Weeks (LA, NY, And Chicago)',
    RADIO_INTERNET_4_WEEK = 'Radio Internet - 4 Week',
    RADIO_INTERNET_8_WEEK_MOVE_OVER = 'Radio Internet - 8 Week Move Over',
    RADIO_INTERNET_8_WEEK_MADE_FOR = 'Radio Internet - 8 Week Made For',
    RADIO_INTERNET_1_YEAR_MOVE_OVER = 'Radio Internet - 1 Year Move Over',
    RADIO_INTERNET_1_YEAR_MADE_FOR = 'Radio Internet - 1 Year Made For',
    RADIO_DEALER_8_WEEK = 'Radio Dealer - 8 Week',
    RADIO_DEALER_6_MONTHS = 'Radio Dealer - 6 Months',
}
