/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateScriptRequest } from '../models/TCreateScriptRequest';
import type { TCreateScriptResponse } from '../models/TCreateScriptResponse';
import type { TDocumentId } from '../models/TDocumentId';
import type { TGetScriptResponse } from '../models/TGetScriptResponse';
import type { TListScriptsResponse } from '../models/TListScriptsResponse';
import type { TScriptId } from '../models/TScriptId';
import type { TUpdateScriptRequest } from '../models/TUpdateScriptRequest';
import type { TUpdateScriptResponse } from '../models/TUpdateScriptResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScriptService {

    /**
     * Get Script
     * @param id
     * @param relations
     * @returns TGetScriptResponse TGetScriptResponse
     * @throws ApiError
     */
    public static getScript(
        id: TScriptId,
        relations?: string,
    ): CancelablePromise<TGetScriptResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scripts/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Script
     * @param id
     * @param requestBody
     * @returns TUpdateScriptResponse TUpdateScriptResponse
     * @throws ApiError
     */
    public static updateScript(
        id: TScriptId,
        requestBody: TUpdateScriptRequest,
    ): CancelablePromise<TUpdateScriptResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scripts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Script
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteScript(
        id: TScriptId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scripts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get Script by Document ID
     * @param docId
     * @param relations
     * @returns TGetScriptResponse TGetScriptResponse
     * @throws ApiError
     */
    public static getScriptByDocId(
        docId: TDocumentId,
        relations?: string,
    ): CancelablePromise<TGetScriptResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scripts/doc/{docId}',
            path: {
                'docId': docId,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * List Scripts
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListScriptsResponse TListScriptsResponse
     * @throws ApiError
     */
    public static listScripts(
        projectId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListScriptsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scripts',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Script
     * @param requestBody
     * @returns TCreateScriptResponse TCreateScriptResponse
     * @throws ApiError
     */
    public static createScript(
        requestBody: TCreateScriptRequest,
    ): CancelablePromise<TCreateScriptResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scripts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
