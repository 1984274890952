/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TGetConversationResponse } from '../models/TGetConversationResponse';
import type { TListConversationsResponse } from '../models/TListConversationsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConversationService {

    /**
     * List Conversations
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListConversationsResponse TListConversationsResponse
     * @throws ApiError
     */
    public static listConversations(
        projectId: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListConversationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chat',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Get Conversation
     * @param id
     * @param relations
     * @returns TGetConversationResponse TGetConversationResponse
     * @throws ApiError
     */
    public static getConversation(
        id: string,
        relations?: string,
    ): CancelablePromise<TGetConversationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chat/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Delete Conversation
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteConversation(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/chat/{id}/delete',
            path: {
                'id': id,
            },
        });
    }

}
