import React, { useEffect, useState } from 'react';
import { Select } from 'grommet';

export const CreateSelect = ({
  defaultOptions,
  defaultValue = '',
  placeholder,
  id,
  onChange,
}: {
  defaultOptions: string[];
  defaultValue?: string;
  placeholder?: string;
  id?: string;
  onChange?: (value: string) => void;
}) => {
  // the prefix name of the Create option entry
  const prefix = ' Create ';

  const updateCreateOption = (text: string) => {
    const len = defaultOptions.length;
    if (len && defaultOptions[len - 1].includes(prefix)) {
      // remove Create option before adding an updated one
      defaultOptions.pop();
    }
    defaultOptions.push(`${prefix}'${text}'`);
  };

  // improving Search support of special characters
  const getRegExp = (text: string) => {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    return new RegExp(escapedText, 'i');
  };
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setOptions(defaultOptions);
    setValue(defaultValue);
  }, [defaultOptions, defaultValue]);

  const handleSearch = (text: string) => {
    updateCreateOption(text);
    const exp = getRegExp(text);
    setOptions(defaultOptions.filter((o: string) => exp.test(o)));
    setSearchValue(text);
  };

  const handleSelection = (option: string) => {
    if (option.includes(prefix)) {
      defaultOptions.pop(); // remove Create option
      defaultOptions.push(searchValue);
      setValue(searchValue);
      onChange && onChange(searchValue);
    } else {
      setValue(option);
      onChange && onChange(option);
    }
    setSearchValue('');
  };

  return (
    <Select
      id={id}
      placeholder={placeholder || 'Select'}
      value={value}
      options={options}
      onChange={({ option }) => handleSelection(option)}
      onClose={() => setOptions(defaultOptions.filter((o: string) => !o.includes(prefix)))}
      onSearch={(text) => handleSearch(text)}
    />
  );
};
