export const USD = (amt: number | bigint | string | undefined) => {
  let val;
  if (!amt) {
    val = 0;
  } else if (typeof amt === 'string') {
    val = parseFloat(amt);
  } else {
    val = amt;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(val);
};
