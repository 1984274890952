/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Costume Type
 */
export enum CostumeType {
    STANDARD = 'Standard',
    EVENING_WEAR = 'Evening Wear',
    PERIOD_WEAR = 'Period Wear',
}
