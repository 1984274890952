import { ApiError } from '/lib/api';

export const toast = {
  login: {
    loading: 'Logging in...',
    success: 'Successfully logged in.',
    error: (err: ApiError) => `An issue occurred logging in: ${err.body.message}`,
  },
  logout: {
    loading: 'Logging out...',
    success: 'Successfully logged out.',
    error: (err: ApiError) => `An issue occurred logging out: ${err.body.message}`,
  },
  requestPasswordReset: {
    loading: 'Processing...',
    success: 'If an account with that email exists, a password reset link will be sent.',
    error: (err: ApiError) => `An issue occurred resetting password: ${err.body.message}`,
  },
  passwordReset: {
    success: 'Password successfully reset.',
    error: (err: ApiError) => `An issue occurred resetting password: ${err.body.message}`,
  },
  signup: {
    loading: 'Processing...',
    success: 'Successfully signed up.  Please login.',
    error: (err: ApiError) => `An issue occurred signing up: ${err.body.message}`,
  },
};
