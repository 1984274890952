import { Box, FormField } from 'grommet';
import { UserPolice } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel, Selector } from '..';
import { useCostumeStore, useProjectStore } from '../../../context';
import { Costume, CostumeType, Role, TScriptId } from '/lib/api';

export interface ICostumePanelProps {
  scriptId?: TScriptId;
  roles: Role[];
}

export const CostumePanel = observer(({ scriptId, roles }: ICostumePanelProps) => {
  const [costumes, setCostumes] = useState<Costume[]>([]);
  const projectStore = useProjectStore();
  const costumeStore = useCostumeStore();

  useEffect(() => {
    if (!scriptId) return;
    const getCostumes = async () => {
      const costumes = await costumeStore.getCostumesByScriptId(scriptId);
      costumes && setCostumes(costumes);
    };
    getCostumes();
  }, [scriptId]);

  const newCostume = async () => {
    if (!scriptId || !projectStore.currentProject?.id) return;
    const costumeRequest = {
      name: 'New Costume',
      type: CostumeType.STANDARD,
      scriptIds: [scriptId],
      projectId: projectStore.currentProject.id,
    };
    const costume = await costumeStore.createCostume(costumeRequest);
    costumes && costume && setCostumes([...costumes, costume]);
  };

  const deleteCostumes = async (ids: string[]) => {
    ids.forEach(async (id) => await costumeStore.deleteCostume(id));
    setCostumes(costumes.filter((r) => !ids.includes(r.id)));
  };

  const updateCostume = async (update: Costume, index: number) => {
    const costume = await costumeStore.updateCostume(update);
    if (!costume) return;
    const newCostumes = [...costumes];
    newCostumes[index] = costume;
    setCostumes(newCostumes);
  };

  return (
    <Panel
      items={costumes}
      newItem={newCostume}
      deleteItems={deleteCostumes}
      updateItem={updateCostume}
      label="Costume"
      labelPlural="Costumes"
      addIcon={<UserPolice />}
      types={[
        {
          label: 'Costume Type',
          key: 'type',
          values: Object.values(CostumeType),
        },
      ]}
    >
      {costumes.map((costume, index) => (
        <Box pad={{ horizontal: 'small' }} key={costume.id}>
          <FormField label="Role/Owner">
            <Selector
              value={costume.owner?.id || ''}
              key="role"
              options={roles}
              onChange={(value: string) =>
                updateCostume({ ...costumes[index], owner: roles.find((r) => r.id === value) }, index)
              }
              size="small"
            />
          </FormField>
        </Box>
      ))}
    </Panel>
  );
});
