import { Anchor, AnchorExtendedProps } from 'grommet/components/Anchor';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import React from 'react';

export const AnchorLink: React.FC<AnchorLinkProps> = (props) => {
  const location = useLocation();
  return <Anchor as={Link} {...props} color={props.to === location.pathname ? 'accent-3' : 'accent-1'} />;
};

export type AnchorLinkProps = LinkProps & AnchorExtendedProps;
