import { Map, Marker } from '@vis.gl/react-google-maps';

export interface IGoogleMapProps {
  apiKey: string;
  position: { lat: number; lng: number };
  zoom: number;
  minHeight?: string;
  id: string;
}

export const GoogleMap = ({ position, zoom, minHeight, id }: IGoogleMapProps) => {
  return (
    <Map
      center={position}
      zoom={zoom}
      style={{ minHeight: minHeight || '200px' }}
      id={id}
      disableDefaultUI
      gestureHandling="none"
    >
      <Marker position={position} />
    </Map>
  );
};
