/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCostumeId } from '../models/TCostumeId';
import type { TCreateCostumeRequest } from '../models/TCreateCostumeRequest';
import type { TCreateCostumeResponse } from '../models/TCreateCostumeResponse';
import type { TGetCostumeResponse } from '../models/TGetCostumeResponse';
import type { TListCostumesResponse } from '../models/TListCostumesResponse';
import type { TUpdateCostumeRequest } from '../models/TUpdateCostumeRequest';
import type { TUpdateCostumeResponse } from '../models/TUpdateCostumeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CostumeService {

    /**
     * Get Costume
     * @param id
     * @param relations
     * @returns TGetCostumeResponse TGetCostumeResponse
     * @throws ApiError
     */
    public static getCostume(
        id: TCostumeId,
        relations?: string,
    ): CancelablePromise<TGetCostumeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/costumes/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Costume
     * @param id
     * @param requestBody
     * @returns TUpdateCostumeResponse TUpdateCostumeResponse
     * @throws ApiError
     */
    public static updateCostume(
        id: TCostumeId,
        requestBody: TUpdateCostumeRequest,
    ): CancelablePromise<TUpdateCostumeResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/costumes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Costume
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteCostume(
        id: TCostumeId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/costumes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Costumes
     * @param scriptId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListCostumesResponse TListCostumesResponse
     * @throws ApiError
     */
    public static listCostumes(
        scriptId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListCostumesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/costumes',
            query: {
                'scriptId': scriptId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Costume
     * @param requestBody
     * @returns TCreateCostumeResponse TCreateCostumeResponse
     * @throws ApiError
     */
    public static createCostume(
        requestBody: TCreateCostumeRequest,
    ): CancelablePromise<TCreateCostumeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/costumes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
