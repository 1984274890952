import {
  Group,
  GroupService,
  TResendGroupInvitesRequest,
} from '/lib/api';
import { makeObservable } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually

export class AccountStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {});
  }

  // GROUPS
  fetchGroups = async () => {
    try {
      const { groups } = await this.q(GroupService.listGroups());
      return groups;
    } catch (e) {
      this.handleError(e);
    }
  };

  getGroup = async (id: string) => {
    try {
      const { group } = await this.q(GroupService.getGroup(id, 'owner,users,active_users,subscription_plan'));
      return group;
    } catch (e) {
      this.handleError(e);
    }
  };

  createGroup = async ({ name, subscription_plan, users }: Partial<Group>) => {
    if (!name || !subscription_plan) {
      throw new Error('Name, subscription plan, and owner are required');
    }
    const request = {
      name,
      subscription_plan_id: subscription_plan.id,
      user_ids: users?.map((u) => u.id),
    };
    try {
      const { group } = await this.q(GroupService.createGroup(request));
      return group;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateGroup = async (
    id: string,
    { name, subscription_plan, users, projects, owner, invited_emails }: Partial<Group>
  ) => {
    if (!name || !subscription_plan) {
      throw new Error('Name and subscription plan are required');
    }
    const request = {
      name,
      subscription_plan_id: subscription_plan.id,
      user_ids: users?.map((u) => u.id),
      project_ids: projects?.map((p) => p.id),
      owner_id: owner?.id,
      invited_emails,
    };
    try {
      const { group } = await this.q(GroupService.updateGroup(id, request));
      return group;
    } catch (e) {
      this.handleError(e);
    }
  };

  acceptGroupInvite = async (t: string) => {
    try {
      const { group, email, token, userId } = await this.q(GroupService.acceptInvitation({ token: t }));
      return { group, email, token, userId };
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteGroupInvite = async ({ email, group_id }: { email: string; group_id: string }) => {
    try {
      return await this.q(GroupService.deleteInvitation(email, group_id));
    } catch (e) {
      this.handleError(e);
    }
  };

  resendInvite = async (req: TResendGroupInvitesRequest) => {
    try {
      await this.q(GroupService.resendInvitation(req));
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteGroup = async (id: string) => {
    try {
      await this.q(GroupService.deleteGroup(id));
    } catch (e) {
      this.handleError(e);
    }
  };
}
