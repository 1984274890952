import { Box, Text } from 'grommet';
import { Video } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel } from '..';
import { useProjectStore, useShootStore } from '../../../context';
import { Shoot } from '/lib/api';

export const ShootPanel = observer(() => {
  const [shoots, setShoots] = useState<Shoot[]>([]);
  const shootStore = useShootStore();
  const projectStore = useProjectStore();
  useEffect(() => {
    const getShoots = async () => {
      if (!projectStore.currentProject) return;
      const shoots = await shootStore.listShoots(projectStore.currentProject.id);
      shoots && setShoots(shoots);
      console.log('Shoots:', shoots);
    };
    getShoots();
  }, [projectStore.currentProject]);

  const newShoot = async () => {
    if (!projectStore.currentProject?.id) return;
    const shoot = await shootStore.createShoot(projectStore.currentProject.id, 'New Shoot');
    shoots && shoot && setShoots([...shoots, shoot]);
  };

  const deleteShoots = async (ids: string[]) => {
    ids.forEach(async (id) => await shootStore.deleteShoot(id));
    setShoots(shoots.filter((r) => !ids.includes(r.id)));
  };

  const updateShoot = async (update: Shoot, index: number) => {
    const shoot = await shootStore.updateShoot(update);
    if (!shoot) return;
    const newShoots = [...shoots];
    newShoots[index] = shoot;
    setShoots(newShoots);
  };

  return (
    <Panel
      items={shoots}
      newItem={newShoot}
      deleteItems={deleteShoots}
      updateItem={updateShoot}
      label="Shoot"
      labelPlural="Shoots"
      addIcon={<Video />}
      types={[]}
    >
      {shoots.map((shoot) =>
        <Box pad="small" key={shoot.estimate?.id}>
          <Text>{shoot.estimate?.title}</Text>
        </Box>
      )}
    </Panel>
  );
});
