/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateLocationRequest } from '../models/TCreateLocationRequest';
import type { TCreateLocationResponse } from '../models/TCreateLocationResponse';
import type { TGetLocationResponse } from '../models/TGetLocationResponse';
import type { TListLocationsResponse } from '../models/TListLocationsResponse';
import type { TLocationId } from '../models/TLocationId';
import type { TUpdateLocationRequest } from '../models/TUpdateLocationRequest';
import type { TUpdateLocationResponse } from '../models/TUpdateLocationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationService {

    /**
     * Get Location
     * @param id
     * @param relations
     * @returns TGetLocationResponse TGetLocationResponse
     * @throws ApiError
     */
    public static getLocation(
        id: TLocationId,
        relations?: string,
    ): CancelablePromise<TGetLocationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Location
     * @param id
     * @param requestBody
     * @returns TUpdateLocationResponse TUpdateLocationResponse
     * @throws ApiError
     */
    public static updateLocation(
        id: TLocationId,
        requestBody: TUpdateLocationRequest,
    ): CancelablePromise<TUpdateLocationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/locations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Location
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteLocation(
        id: TLocationId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/locations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Locations
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListLocationsResponse TListLocationsResponse
     * @throws ApiError
     */
    public static listLocations(
        projectId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListLocationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Location
     * @param requestBody
     * @returns TCreateLocationResponse TCreateLocationResponse
     * @throws ApiError
     */
    public static createLocation(
        requestBody: TCreateLocationRequest,
    ): CancelablePromise<TCreateLocationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
