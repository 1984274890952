/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Prop Type
 */
export enum PropType {
    AUTOMOBILE = 'Automobile',
    TRAILER = 'Trailer',
    MOTORCYCLE = 'Motorcycle',
    SKATES = 'Skates',
    SKATEBOARD = 'Skateboard',
    BICYCLE = 'Bicycle',
    MOPED = 'Moped',
    CAMERA = 'Camera',
    PET = 'Pet',
    FOOTWEAR = 'Footwear',
    LUGGAGE = 'Luggage',
    GOLF_CLUBS = 'Golf Clubs',
    GOLF_BAG = 'GolfBag',
    TENNIS_RACQUET = 'Tennis Racquet',
    SKIS = 'Skis',
    BINOCULARS = 'Binoculars',
    OPERA_GLASSES = 'Opera Glasses',
    LARGE_PORTABLE_RADIO = 'Large Portable Radio',
    BOOK = 'Book',
    LAPTOP = 'Laptop',
    CELL_PHONE = 'CellPhone',
    TABLET = 'Tablet',
    PDA = 'PDA',
    MP3_PLAYER = 'MP3 Player',
    OTHER = 'Other',
}
