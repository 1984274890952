import { Box, Button, Heading, Layer, Select, Text, Tip } from 'grommet';
import { Table, Video } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AddNewButton, Delete, EditableText } from '..';
import { Shoot } from '/lib/api';
import { useEstimateStore, useProjectStore, useShootStore } from '/src/context';

export interface IEstimateListProps {
  gridArea?: string;
}

export const EstimateList = observer(({ gridArea }: IEstimateListProps) => {
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();
  const shootStore = useShootStore();
  const [showModal, setShowModal] = useState(false);
  const [shoots, setShoots] = useState<Shoot[]>([]);
  const [newEstimateTitle, setNewEstimateTitle] = useState<string>('');
  const [newEstimateShootId, setNewEstimateShootId] = useState<string>('');

  useEffect(() => {
    if (!projectStore.currentProject?.id) return;
    if (estimateStore.estimateList?.length && !estimateStore.currentEstimate) {
      estimateStore.setCurrentEstimate(estimateStore.estimateList[0]);
    }
  }, [estimateStore.estimateList, projectStore.currentProject]);

  useEffect(() => {
    const getShoots = async () => {
      if (!projectStore.currentProject?.id) return;
      const sh = await shootStore.listShoots(projectStore.currentProject.id);
      setShoots(sh || []);
    };
    getShoots();
  }, [projectStore.currentProject, showModal]);

  const deleteEstimate = async (id: string) => {
    await estimateStore.deleteEstimate(id);
  };

  const showOrBypassModal = () => {
    if (shoots.length > 1) {
      setShowModal(true);
    } else {
      setNewEstimateShootId(shoots[0].id);
      setNewEstimateTitle(`${shoots[0].name} Estimate`); // e.g. `Shoot 1 Estimate`
      setNewEstimate();
    }
  };

  const setNewEstimate = async () => {
    // --OLD CODE--
    //
    // console.warn('EstimateList.setNewEstimate is currently disabled for debugging');
    // if (!projectStore.currentProject?.id) return;
    // const shootId = projectStore.currentProject.shoots[0]?.id;
    // const estimate = await estimateStore.createEstimate(projectStore.currentProject.id, shootId);
    // if (estimate) {
    //   // update the current estimate
    //   estimateStore.setCurrentEstimate(estimate);
    // }
    //
    // --END OLD CODE--
    if (!projectStore.currentProject?.id || !newEstimateShootId || !newEstimateTitle) return;

    const estimate = await estimateStore.createEstimate(
      projectStore.currentProject.id,
      newEstimateTitle,
      newEstimateShootId
    );
    if (estimate) {
      // update the current estimate
      estimateStore.setCurrentEstimate(estimate);
    }
    setShowModal(false);
  };

  return (
    <Box
      direction="column"
      pad="medium"
      overflow={{ vertical: 'auto' }}
      align="start"
      id="Estimate-list"
      gridArea={gridArea}
    >
      <Box direction="row">
        <Heading level="3" margin="medium">
          Estimates
        </Heading>
        <AddNewButton onClick={() => showOrBypassModal()} tipText="New Estimate" icon={<Table />} />
      </Box>
      {estimateStore.estimateList?.map((e) => (
        <Box direction="row" key={e.id}>
          <Tip
            content={
              <Box width={{ max: 'medium' }} background="light-2" round="small" pad="small" margin="small" border="all">
                <Text>{e.title}</Text>
              </Box>
            }
            plain={true}
          >
            <Button
              pad="small"
              margin="small"
              hoverIndicator
              onClick={() => estimateStore.setCurrentEstimate(e)}
              label={e.title.length > 35 ? `${e.title.slice(0, 34)}...` : e.title}
              active={e.id === estimateStore.currentEstimate?.id}
            />
          </Tip>
          <Delete onClick={() => deleteEstimate(e.id)} name={e.title} />
        </Box>
      ))}
      {!estimateStore.estimateList?.length && <Text>No previous Estimates found for this project.</Text>}
      {showModal && (
        <Layer
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
          position="center"
          modal={true}
          margin="large"
        >
          <Box
            direction="column"
            align="start"
            pad="small"
            justify="around"
            width="medium"
            height="medium"
            background="light-1"
            round="small"
          >
            <Box direction="row" pad="small" gap="small">
              <Text>Title:</Text>
              <EditableText
                val={estimateStore.currentEstimate?.title}
                onChange={setNewEstimateTitle}
                textSize="xlarge"
              />
            </Box>
            <Box direction="row" gap="small" pad="small" align="center">
              <Text>Shoot:</Text>
              <Select
                id="select-shoot"
                name="shoot"
                placeholder="Shoot"
                options={shoots}
                labelKey="name"
                valueKey="id"
                onChange={({ value }) => setNewEstimateShootId(value.id)}
                icon={<Video />}
              />
            </Box>
            <Button
              pad="small"
              margin="small"
              hoverIndicator
              onClick={() => setNewEstimate()}
              label="Create Estimate"
            />
          </Box>
        </Layer>
      )}
    </Box>
  );
});
