/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateSessionEstimateRequest } from '../models/TCreateSessionEstimateRequest';
import type { TCreateSessionEstimateResponse } from '../models/TCreateSessionEstimateResponse';
import type { TGetSessionEstimateResponse } from '../models/TGetSessionEstimateResponse';
import type { TListSessionEstimatesResponse } from '../models/TListSessionEstimatesResponse';
import type { TRefreshSessionEstimateResponse } from '../models/TRefreshSessionEstimateResponse';
import type { TSessionEstimateId } from '../models/TSessionEstimateId';
import type { TSessionId } from '../models/TSessionId';
import type { TUpdateSessionEstimateRequest } from '../models/TUpdateSessionEstimateRequest';
import type { TUpdateSessionEstimateResponse } from '../models/TUpdateSessionEstimateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionEstimateService {

    /**
     * Get Session Estimate
     * @param id
     * @param relations
     * @returns TGetSessionEstimateResponse TGetSessionEstimateResponse
     * @throws ApiError
     */
    public static getSessionEstimate(
        id: TSessionEstimateId,
        relations?: string,
    ): CancelablePromise<TGetSessionEstimateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimates/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Session Estimate
     * @param id
     * @param requestBody
     * @returns TUpdateSessionEstimateResponse TUpdateSessionEstimateResponse
     * @throws ApiError
     */
    public static updateSessionEstimate(
        id: TSessionEstimateId,
        requestBody: TUpdateSessionEstimateRequest,
    ): CancelablePromise<TUpdateSessionEstimateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/session-estimates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Session Estimate
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSessionEstimate(
        id: TSessionEstimateId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/session-estimates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get Session Estimate by Session ID
     * @param sessionId
     * @param relations
     * @returns TGetSessionEstimateResponse TGetSessionEstimateBySessionIdResponse
     * @throws ApiError
     */
    public static getBySessionId(
        sessionId: TSessionId,
        relations?: string,
    ): CancelablePromise<TGetSessionEstimateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimates/sessions/{session_id}',
            path: {
                'session_id': sessionId,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * List Session Estimates
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListSessionEstimatesResponse TListSessionEstimatesResponse
     * @throws ApiError
     */
    public static listSessionEstimates(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListSessionEstimatesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimates',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Session Estimate
     * @param requestBody
     * @returns TCreateSessionEstimateResponse TCreateSessionEstimateResponse
     * @throws ApiError
     */
    public static createSessionEstimate(
        requestBody: TCreateSessionEstimateRequest,
    ): CancelablePromise<TCreateSessionEstimateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session-estimates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Refresh Session Estimate
     * @param id
     * @returns TRefreshSessionEstimateResponse TRefreshSessionEstimateResponse
     * @throws ApiError
     */
    public static refreshSessionEstimate(
        id: TSessionEstimateId,
    ): CancelablePromise<TRefreshSessionEstimateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/session-estimates/{id}/refresh',
            path: {
                'id': id,
            },
        });
    }

}
