/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateRoleActivityRequest } from '../models/TCreateRoleActivityRequest';
import type { TCreateRoleActivityResponse } from '../models/TCreateRoleActivityResponse';
import type { TGetRoleActivityResponse } from '../models/TGetRoleActivityResponse';
import type { TListRoleActivitiesResponse } from '../models/TListRoleActivitiesResponse';
import type { TRoleActivityId } from '../models/TRoleActivityId';
import type { TUpdateRoleActivityRequest } from '../models/TUpdateRoleActivityRequest';
import type { TUpdateRoleActivityResponse } from '../models/TUpdateRoleActivityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleActivityService {

    /**
     * Get RoleActivity
     * @param id
     * @param relations
     * @returns TGetRoleActivityResponse TGetRoleActivityResponse
     * @throws ApiError
     */
    public static getRoleActivity(
        id: TRoleActivityId,
        relations?: string,
    ): CancelablePromise<TGetRoleActivityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role-activities/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update RoleActivity
     * @param id
     * @param requestBody
     * @returns TUpdateRoleActivityResponse TUpdateRoleActivityResponse
     * @throws ApiError
     */
    public static updateRoleActivity(
        id: TRoleActivityId,
        requestBody: TUpdateRoleActivityRequest,
    ): CancelablePromise<TUpdateRoleActivityResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/role-activities/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete RoleActivity
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteRoleActivity(
        id: TRoleActivityId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/role-activities/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List RoleActivities
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListRoleActivitiesResponse TListRoleActivitiesResponse
     * @throws ApiError
     */
    public static listRoleActivities(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListRoleActivitiesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role-activities',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create RoleActivity
     * @param requestBody
     * @returns TCreateRoleActivityResponse TCreateRoleActivityResponse
     * @throws ApiError
     */
    public static createRoleActivity(
        requestBody: TCreateRoleActivityRequest,
    ): CancelablePromise<TCreateRoleActivityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/role-activities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
