import { Box } from 'grommet';
import { MapLocation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GoogleMap, GoogleMapAutoComplete, Panel } from '..';
import { useProjectStore } from '../../../context';
import { Location, LocationType, StudioZone } from '/lib/api';
import { getAddressObject } from '../../../utils/decodeGoogle';

export const LocationPanel = observer(() => {
  const [locations, setLocations] = useState<Location[]>([]);
  const projectStore = useProjectStore();
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getApiKey = async () => {
      const apiKey = await projectStore.getMapApiKey();
      setApiKey(apiKey);
    };
    getApiKey();
  }, []);

  useEffect(() => {
    const projectId = projectStore.currentProject?.id;
    if (!projectId) return;
    const getLocations = async () => {
      const locs = await projectStore.getLocationsByProjectId(projectId);
      setLocations(locs || []);
    };
    getLocations();
  }, [projectStore.currentProject?.id]);

  const newLocation = async () => {
    if (!projectStore.currentProject?.id) return;
    const locationRequest = {
      name: 'New Location',
      type: LocationType.NEARBY,
      streetAddress: '',
      aptOrUnit: '',
      city: '',
      state: '',
      zip: '',
      projectId: projectStore.currentProject.id,
    };
    const location = await projectStore.createLocation(locationRequest);
    if (!location) return;
    setLocations([...locations, location]);
  };

  const deleteLocations = async (ids: string[]) => {
    for (const id of ids) {
      await projectStore.deleteLocation(id);
    }
    setLocations(locations.filter((l) => !ids.includes(l.id)));
  };

  const updateLocation = async (update: Location, index: number) => {
    const location = await projectStore.updateLocation(update);
    if (!location) return;
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  const updatePlace = async (place: google.maps.places.PlaceResult, index: number) => {
    if (!place.address_components) return;
    const address = getAddressObject(place.address_components);
    address.lat = place.geometry?.location?.lat() || -1;
    address.lng = place.geometry?.location?.lng() || -1;

    updateLocation({ ...locations[index], ...address }, index);
  };

  return (
    <Panel
      items={locations}
      newItem={newLocation}
      deleteItems={deleteLocations}
      updateItem={updateLocation}
      label="Location"
      labelPlural="Locations"
      addIcon={<MapLocation />}
      types={[
        {
          label: 'Location Type',
          key: 'type',
          values: Object.values(LocationType),
        },
        {
          label: 'Studio Zone',
          key: 'studio_zone',
          values: Object.values(StudioZone),
          readonly: true,
        },
      ]}
    >
      {locations.map((location, i) => (
        <Box direction="column" key={location.id} pad="small">
          {apiKey && (
            <APIProvider apiKey={apiKey} libraries={['places']}>
              <Box pad="small">
                <GoogleMapAutoComplete
                  onPlaceSelected={(place) => updatePlace(place, i)}
                  defaultValue={
                    location.street_address !== ''
                      ? `${location.street_address} ${location.city} ${location.state} ${location.zip}`
                      : undefined
                  }
                  options={{
                    componentRestrictions: { country: 'us' },
                    types: ['address'],
                    fields: ['address_components', 'geometry', 'name'],
                  }}
                  placeholder="Search for a location..."
                />
              </Box>
              {!!(location.lat && location.lng) && (
                <GoogleMap
                  apiKey={apiKey || ''}
                  position={{ lat: location.lat, lng: location.lng }}
                  zoom={10}
                  id={location.id}
                />
              )}
            </APIProvider>
          )}
        </Box>
      ))}
    </Panel>
  );
});
