import { Box, CheckBox, Select, SelectMultiple, Text } from 'grommet';
import { Bike, MapLocation, Group, User, UserPolice, Yoga } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import {
  Costume,
  Location,
  Prop,
  Role,
  RoleGroup,
  Script,
  Session,
  SessionActivity,
  SessionActivityType,
  SessionEstimate,
} from '/lib/api';
import { DateTimeDropButton, Delete, TalonTip } from '../..';
import { RoleActivityRow } from '.';

interface ISessionActivityRowProps {
  script?: Script;
  session: Session;
  activity: SessionActivity;
  costumes: Costume[];
  props: Prop[];
  roles: Role[];
  roleGroups: RoleGroup[];
  locations: Location[];
  sessionEstimate: SessionEstimate;
  deleteSessionActivity: (activityId: string) => Promise<void>;
  updateSessionActivity: (update: Partial<SessionActivity>) => Promise<void>;
}

export const SessionActivityRow = observer(
  ({
    script,
    session,
    activity,
    costumes,
    props,
    roles,
    roleGroups,
    locations,
    deleteSessionActivity,
    updateSessionActivity,
  }: ISessionActivityRowProps) => {
    const getBounds = (startDate: string) => {
      const d = new Date(startDate);
      const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
      const plusOneYear = new Date(d.getFullYear() + 1, d.getMonth(), 1);
      return [firstDayOfMonth.toISOString(), plusOneYear.toISOString()];
    };

    const getDisabled = (date: string) => {
      const d = new Date(date);
      const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
      const dayBefore = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
      return d.getDate() === 1 ? [] : [[firstDayOfMonth.toISOString(), dayBefore.toISOString()]];
    };

    const TravelActivityTypes = [SessionActivityType.TRAVEL_TO_LOCATION, SessionActivityType.TRAVEL_FROM_LOCATION];

    // const MealActivityTypes = [
    //   SessionActivityType.MEAL_BREAKFAST,
    //   SessionActivityType.MEAL_LUNCH,
    //   SessionActivityType.MEAL_DINNER,
    // ];

    const WorkActivityTypes = [
      SessionActivityType.WORK_TIME,
      SessionActivityType.HAIR_AND_MAKEUP,
      SessionActivityType.FITTING,
      SessionActivityType.REHEARSAL,
      SessionActivityType.RECORDING_SESSION,
      SessionActivityType.VOICE_OVER,
    ];

    const AuditionActivityTypes = [
      SessionActivityType.AUDITION,
      SessionActivityType.AUDITION_SCRATCH_TRACK,
      SessionActivityType.CREATIVE_AUDITION,
    ];

    return (
      <Box direction="row" border={{ side: 'top', color: 'light-3' }}>
        <Box direction="column">
          <Box direction="row" align="center" gap="medium">
            <DateTimeDropButton
              onChange={(date) => date && updateSessionActivity({ start_time: date.toISOString() })}
              placeholder="Start time"
              date={new Date(activity.start_time).toDateString()}
              time={new Date(activity.start_time).toLocaleTimeString()}
              bounds={getBounds(session.date)}
              disabled={getDisabled(session.date)}
            />
            <Text alignSelf="center">to</Text>
            <DateTimeDropButton
              onChange={(date) => date && updateSessionActivity({ end_time: date.toISOString() })}
              placeholder="End time"
              date={new Date(activity.end_time).toISOString()}
              time={new Date(activity.end_time).toLocaleTimeString()}
              bounds={getBounds(session.date)}
              disabled={getDisabled(new Date(activity.start_time).toISOString() || session.date)}
            />
            <Select
              options={Object.values(SessionActivityType)}
              value={activity.type}
              placeholder="Choose activity type..."
              size="xsmall"
              onChange={({ value }) => updateSessionActivity({ type: value })}
              icon={
                <TalonTip content={<Text>Choose Activity Type</Text>}>
                  <Yoga />
                </TalonTip>
              }
            />
            <SelectMultiple
              options={roles}
              value={activity.roles?.reduce((acc, curr) => (curr.role_group ? acc : acc.concat(curr)), [] as Role[])}
              valueKey="id"
              labelKey="name"
              placeholder="Choose roles..."
              size="medium"
              onChange={({ value }) => updateSessionActivity({ roles: value })}
              showSelectedInline
              disabled={!script?.id}
              icon={
                <TalonTip content={<Text>Choose Roles</Text>}>
                  <User />
                </TalonTip>
              }
              valueLabel={(values) => (
                <Box wrap direction="column" width="small">
                  {values && values.length ? (
                    <>
                      {values.map((role: Role) => {
                        return <RoleActivityRow role={role} activity={activity} key={role.id} />;
                      })}
                    </>
                  ) : (
                    <Text color="text-weak">Select Roles</Text>
                  )}
                </Box>
              )}
            />
            <SelectMultiple
              options={roleGroups}
              value={activity.role_groups}
              valueKey="id"
              labelKey="name"
              placeholder="Choose role groups..."
              size="medium"
              onChange={({ value }) => updateSessionActivity({ role_groups: value })}
              showSelectedInline
              disabled={!script?.id}
              icon={
                <TalonTip content={<Text>Choose Role Groups</Text>}>
                  <Group />
                </TalonTip>
              }
            />
            {activity.type === SessionActivityType.WARDROBE_CHANGE && (
              <SelectMultiple
                options={costumes}
                value={activity.costumes}
                valueKey="id"
                labelKey="name"
                placeholder="Choose costumes..."
                size="medium"
                onChange={({ value }) => updateSessionActivity({ costumes: value })}
                showSelectedInline
                disabled={!script?.id}
                icon={
                  <TalonTip content={<Text>Choose Costumes</Text>}>
                    <UserPolice />
                  </TalonTip>
                }
              />
            )}
            {WorkActivityTypes.includes(activity.type) && (
              <SelectMultiple
                options={props}
                value={activity.props}
                valueKey="id"
                labelKey="name"
                placeholder="Choose props..."
                size="medium"
                onChange={({ value }) => updateSessionActivity({ props: value })}
                showSelectedInline
                disabled={!script?.id}
                icon={
                  <TalonTip content={<Text>Choose Props</Text>}>
                    <Bike />
                  </TalonTip>
                }
              />
            )}
            {!TravelActivityTypes.includes(activity.type) && (
              <Box direction="column" gap="small">
                <Select
                  options={locations}
                  value={activity.location}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Choose location..."
                  size="medium"
                  icon={
                    <TalonTip content={<Text>Choose Location</Text>}>
                      <MapLocation />
                    </TalonTip>
                  }
                  onChange={({ value }) => updateSessionActivity({ location: value })}
                />
              </Box>
            )}
            {TravelActivityTypes.includes(activity.type) && (
              <Box direction="row" gap="small">
                <Select
                  options={locations.filter((l) => l.id !== activity.to_location?.id)}
                  value={activity.from_location}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Choose travel from location..."
                  size="medium"
                  icon={
                    <TalonTip content={<Text>Choose From Location</Text>}>
                      <MapLocation />
                    </TalonTip>
                  }
                  onChange={({ value }) => updateSessionActivity({ from_location: value })}
                />
                <Text alignSelf="center">to</Text>
                <Select
                  options={locations.filter((l) => l.id !== activity.from_location?.id)}
                  value={activity.to_location}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Choose travel to location..."
                  size="medium"
                  icon={
                    <TalonTip content={<Text>Choose To Location</Text>}>
                      <MapLocation />
                    </TalonTip>
                  }
                  onChange={({ value }) => updateSessionActivity({ to_location: value })}
                />
              </Box>
            )}

            {AuditionActivityTypes.includes(activity.type) && (
              <Box direction="column" gap="small">
                <CheckBox
                  label="Scratch track"
                  checked={activity.is_scratch_track}
                  onChange={(e) => updateSessionActivity({ is_scratch_track: e.target.checked })}
                />
                <CheckBox
                  label="Creative session"
                  checked={activity.is_creative_session}
                  onChange={(e) => updateSessionActivity({ is_creative_session: e.target.checked })}
                />
              </Box>
            )}
            <Delete
              onClick={() => deleteSessionActivity(activity.id)}
              name={`${activity.type} ${new Date(activity.start_time).toLocaleTimeString()} to ${new Date(
                activity.end_time
              ).toLocaleTimeString()}`}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
