import React from 'react';
import ReactDOM from 'react-dom/client';
import { Grommet } from 'grommet';
import { ErrorBoundary } from 'react-error-boundary';
import theme from './theme';
import '/src/styles/globals.css';
import { RootContextProvider } from '/src/context';
import { ErrorFallback, ChatWidget } from '/src/components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { PostHogProvider } from 'posthog-js/react';
import { config } from './config';

const { posthog } = config.env;
const root = document.getElementById('app');
root && ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <PostHogProvider apiKey={posthog.api_key} options={posthog.options}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <RootContextProvider>
          <BrowserRouter>
            <Grommet theme={theme} className="gommet-container">
              <AppRoutes />
              <ChatWidget />
              <ToastContainer position="bottom-right" />
            </Grommet>
          </BrowserRouter>
        </RootContextProvider>
      </ErrorBoundary>
    </PostHogProvider>
  </React.StrictMode>
);
