/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateEditRequest } from '../models/TCreateEditRequest';
import type { TCreateEditResponse } from '../models/TCreateEditResponse';
import type { TEditId } from '../models/TEditId';
import type { TGetEditResponse } from '../models/TGetEditResponse';
import type { TListEditsResponse } from '../models/TListEditsResponse';
import type { TUpdateEditRequest } from '../models/TUpdateEditRequest';
import type { TUpdateEditResponse } from '../models/TUpdateEditResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EditService {

    /**
     * Get Edit
     * @param id
     * @param relations
     * @returns TGetEditResponse TGetEditResponse
     * @throws ApiError
     */
    public static getEdit(
        id: TEditId,
        relations?: string,
    ): CancelablePromise<TGetEditResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/edits/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Edit
     * @param id
     * @param requestBody
     * @returns TUpdateEditResponse TUpdateEditResponse
     * @throws ApiError
     */
    public static updateEdit(
        id: TEditId,
        requestBody: TUpdateEditRequest,
    ): CancelablePromise<TUpdateEditResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/edits/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Edit
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteEdit(
        id: TEditId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/edits/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Edits
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListEditsResponse TListEditsResponse
     * @throws ApiError
     */
    public static listEdits(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListEditsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/edits',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Edit
     * @param requestBody
     * @returns TCreateEditResponse TCreateEditResponse
     * @throws ApiError
     */
    public static createEdit(
        requestBody: TCreateEditRequest,
    ): CancelablePromise<TCreateEditResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/edits',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
