import { Box, Nav } from 'grommet';
import { AnchorLink } from './AnchorLink';
import { Table, DocumentText, Home, Video } from 'grommet-icons';
import { UserMenu } from './UserMenu';
import TalonLogo from 'jsx:/public/images/talon-ai-logo.svg';
import { Selector } from '..';
import { useProjectStore } from '/src/context';
import { observer } from 'mobx-react-lite';

export const HeaderNav = observer(() => {
  const projectStore = useProjectStore();

  return (
    <Nav direction="row" background="brand" flex="grow" justify="evenly" pad="small">
      <AnchorLink
        to="/"
        label="TALON AI"
        icon={<TalonLogo width="40" height="40" color="white" />}
        alignSelf="center"
      />
      {projectStore.currentProject?.id && (
        <Box alignSelf="center">
          <Selector
            options={projectStore.projectList}
            value={projectStore.currentProject.id}
            onChange={(val) => projectStore.setCurrentProjectId(val)}
          />
        </Box>
      )}
      <AnchorLink
        to={projectStore.currentProject?.id ? '/project' : '#'}
        label="Project Home"
        icon={<Home />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />

      <AnchorLink
        to={projectStore.currentProject?.id ? '/shoot-planner' : '#'}
        label="Shoot Planner"
        icon={<Video />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <AnchorLink
        to={projectStore.currentProject?.id ? '/estimate' : '#'}
        label="Estimate"
        icon={<Table />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <AnchorLink
        to={projectStore.currentProject?.id ? '/contracting' : '#'}
        label="Contracting"
        icon={<DocumentText />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <Box direction="column" alignSelf="center" justify="center">
        <UserMenu />
      </Box>
    </Nav>
  );
});
