/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Group } from '../models/Group';
import type { TAcceptGroupInviteRequest } from '../models/TAcceptGroupInviteRequest';
import type { TAcceptGroupInviteResponse } from '../models/TAcceptGroupInviteResponse';
import type { TCreateGroupRequest } from '../models/TCreateGroupRequest';
import type { TCreateGroupResponse } from '../models/TCreateGroupResponse';
import type { TGetGroupResponse } from '../models/TGetGroupResponse';
import type { TGroupId } from '../models/TGroupId';
import type { TListGroupsResponse } from '../models/TListGroupsResponse';
import type { TResendGroupInvitesRequest } from '../models/TResendGroupInvitesRequest';
import type { TUpdateGroupRequest } from '../models/TUpdateGroupRequest';
import type { TUpdateGroupResponse } from '../models/TUpdateGroupResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GroupService {

    /**
     * Get Group
     * @param id
     * @param relations
     * @returns TGetGroupResponse TGetGroupResponse
     * @throws ApiError
     */
    public static getGroup(
        id: TGroupId,
        relations?: string,
    ): CancelablePromise<TGetGroupResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Group
     * @param id
     * @param requestBody
     * @returns TUpdateGroupResponse TUpdateGroupResponse
     * @throws ApiError
     */
    public static updateGroup(
        id: TGroupId,
        requestBody: TUpdateGroupRequest,
    ): CancelablePromise<TUpdateGroupResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Group
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteGroup(
        id: TGroupId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Groups
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListGroupsResponse TListGroupsResponse
     * @throws ApiError
     */
    public static listGroups(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListGroupsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/groups',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Group
     * @param requestBody
     * @returns TCreateGroupResponse TCreateGroupResponse
     * @throws ApiError
     */
    public static createGroup(
        requestBody: TCreateGroupRequest,
    ): CancelablePromise<TCreateGroupResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Accept a group invitation
     * @param requestBody
     * @returns TAcceptGroupInviteResponse TAcceptGroupInviteResponse
     * @throws ApiError
     */
    public static acceptInvitation(
        requestBody: TAcceptGroupInviteRequest,
    ): CancelablePromise<TAcceptGroupInviteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/groups/accept-invitation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Resend a group invitation
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static resendInvitation(
        requestBody: TResendGroupInvitesRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/groups/resend-invitation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a group invitation
     * @param email
     * @param groupId
     * @returns Group void
     * @throws ApiError
     */
    public static deleteInvitation(
        email: string,
        groupId: TGroupId,
    ): CancelablePromise<Group> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/groups/invitation',
            query: {
                'email': email,
                'group_id': groupId,
            },
        });
    }

}
