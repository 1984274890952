import { lazy, Suspense, useState, useEffect } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useUserStore } from '/src/context';
import { observer } from 'mobx-react-lite';
import { Loader } from '/src/components/ui';
import { toast } from 'react-toastify';
import * as jwt from 'jsonwebtoken';

const Home = lazy(() => import('/src/pages/home'));
const Login = lazy(() => import('/src/pages/login'));
const Chat = lazy(() => import('/src/pages/chat'));
const ShootPlanner = lazy(() => import('/src/pages/shoot-planner'));
const Estimate = lazy(() => import('/src/pages/estimate'));
const Contracting = lazy(() => import('/src/pages/contracting'));
const Profile = lazy(() => import('/src/pages/profile'));
const Account = lazy(() => import('/src/pages/account'));
const ProjectHome = lazy(() => import('./pages/project-home'));
const ResetPassword = lazy(() => import('./pages/reset-password'));
const Signup = lazy(() => import('./pages/signup'));
const Admin = lazy(() => import('./pages/admin'));
const ConfirmEmail = lazy(() => import('./pages/confirm-email'));

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      {/* prettier-ignore */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/group-invitation" element={<Signup />} />
        <Route path="/confirm" element={<ConfirmEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<RequireAuth><Home /></RequireAuth>}/>
        <Route path="/chat" element={<RequireAuth><Chat /></RequireAuth>}/>
        <Route path="/shoot-planner" element={<RequireAuth><ShootPlanner /></RequireAuth>}/>
        <Route path="/estimate" element={<RequireAuth><Estimate /></RequireAuth>}/>
        <Route path="/contracting" element={<RequireAuth><Contracting /></RequireAuth>}/>
        <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>}/>
        <Route path="/account" element={<RequireAuth><Account /></RequireAuth>}/>
        <Route path="/project" element={<RequireAuth><ProjectHome /></RequireAuth>}/>
        <Route path="/admin" element={<RequireAuth adminOnly><Admin /></RequireAuth>}/>
      </Routes>
    </Suspense>
  );
};

const RequireAuth = observer(({ adminOnly, children }: { adminOnly?: boolean; children: JSX.Element }) => {
  const { token, user, logout } = useUserStore();
  const location = useLocation();
  const [destination, setDestination] = useState<string | undefined>(undefined);
  useEffect(() => {
    const exp = jwt.decode(token || '', { json: true })?.exp;
    const valid = exp ? exp * 1000 > Date.now() : false;
    if (!token || !valid || !user) {
      setDestination('/login');
      logout();
      toast.error('Your session has expired. Please login again.');
    } else if (adminOnly && !user.is_admin) {
      setDestination('/');
    } else {
      setDestination(undefined);
    }
  }, [token, logout, children]);

  return destination ? <Navigate to={destination} state={{ from: location }} replace /> : children;
});
