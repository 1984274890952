/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type
 */
export enum CommercialType {
    TV = 'TV',
    RADIO = 'Radio',
}
