/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateUsageRequest } from '../models/TCreateUsageRequest';
import type { TCreateUsageResponse } from '../models/TCreateUsageResponse';
import type { TGetUsageResponse } from '../models/TGetUsageResponse';
import type { TListUsagesResponse } from '../models/TListUsagesResponse';
import type { TUpdateUsageRequest } from '../models/TUpdateUsageRequest';
import type { TUpdateUsageResponse } from '../models/TUpdateUsageResponse';
import type { TUsageId } from '../models/TUsageId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsageService {

    /**
     * Get Usage
     * @param id
     * @param relations
     * @returns TGetUsageResponse TGetUsageResponse
     * @throws ApiError
     */
    public static getUsage(
        id: TUsageId,
        relations?: string,
    ): CancelablePromise<TGetUsageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usages/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Usage
     * @param id
     * @param requestBody
     * @returns TUpdateUsageResponse TUpdateUsageResponse
     * @throws ApiError
     */
    public static updateUsage(
        id: TUsageId,
        requestBody: TUpdateUsageRequest,
    ): CancelablePromise<TUpdateUsageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/usages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Usage
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteUsage(
        id: TUsageId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/usages/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Usages
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListUsagesResponse TListUsagesResponse
     * @throws ApiError
     */
    public static listUsages(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListUsagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usages',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Usage
     * @param requestBody
     * @returns TCreateUsageResponse TCreateUsageResponse
     * @throws ApiError
     */
    public static createUsage(
        requestBody: TCreateUsageRequest,
    ): CancelablePromise<TCreateUsageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/usages',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
