/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Allowance
 */
export enum AllowanceType {
    WARDROBE_FEE = 'Wardrobe Fee',
    WARDROBE_FEE_EVE_PERIOD = 'Wardrobe Fee - Eve/Period',
    BREAKFAST = 'Breakfast',
    LUNCH = 'Lunch',
    DINNNER = 'Dinnner',
    EXTRAS_ROUND_TRIP = 'Extras - Round Trip',
    PER_MILE_OUTSIDE_STUDIO_ZONE_ = 'Per mile (outside studio zone)',
    OCP_TRAVEL_PER_DIEM = 'OCP travel per diem',
    AUTOMOBILE_TRAILER_OR_MOTORCYCLE = 'Automobile, Trailer or Motorcycle',
    SKATES_AND_SKATE_BOARDS = 'Skates and Skate Boards',
    BICYCLE = 'Bicycle',
    MOPED = 'Moped',
    CAMERA = 'Camera',
    PET = 'Pet',
    LUGGAGE_EACH_PIECE_ = 'Luggage (each piece)',
    GOLF_CLUBS_BAG = 'Golf Clubs & Bag',
    TENNIS_RACQUET = 'Tennis Racquet',
    SKIS = 'Skis',
    BINOCULARS_OR_OPERA_GLASSES = 'Binoculars or Opera Glasses',
    LARGE_PORTABLE_RADIOS = 'Large Portable Radios',
    BOOKS_EACH_ = 'Books (each)',
    LAPTOP_CELL_PHONE_PDA_I_POD_MP3_PLAYER = 'Laptop, Cell Phone/PDA, iPod/MP3 Player',
    STANDARD_OPENING_OR_CLOSING = 'Standard Opening or Closing',
    WET_SNOW_SMOKE_DUST_WORK = 'Wet, Snow, Smoke, & Dust Work',
    BODY_MU_SKULL_CAP_HAIR_GOODS_HAIRCUTS = 'Body MU, Skull Cap, Hair Goods, Haircuts',
}
