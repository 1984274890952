import { Group } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel } from '..';
import { useProjectStore, useRoleStore } from '../../../context';
import { PerformerType, RoleGroup, RoleType } from '/lib/api';
import { filterRoleTypes } from '/src/utils/listFilters';

export interface IRoleGroupPanelProps {
  scriptId?: string;
}

type ExtendedRoleGroup = RoleGroup & { members?: string; performer_type?: PerformerType; type?: RoleType };

export const RoleGroupPanel = observer(({ scriptId }: IRoleGroupPanelProps) => {
  const [roleGroups, setRoleGroups] = useState<ExtendedRoleGroup[]>([]);
  const roleStore = useRoleStore();
  const projectStore = useProjectStore();

  useEffect(() => {
    if (!scriptId) return;
    const getRoleGroups = async () => {
      const roleGroups = await roleStore.getRoleGroupsByScriptId(scriptId);
      const extendedRoleGroups = roleGroups?.map((rg) => ({
        ...rg,
        members: rg.roles.length.toString(),
        performer_type: rg.roles[0].performer_type,
        type: rg.roles[0].type,
      }));

      setRoleGroups(extendedRoleGroups || []);
    };
    getRoleGroups();
  }, [scriptId]);

  const newRoleGroup = async () => {
    if (!scriptId || !projectStore.currentProject?.id) return;
    //first, create 3 new roles, the minimum for a group
    const roles = [];
    for (let i = 0; i < 3; i++) {
      const roleRequest = {
        name: `New Group Role ${i + 1}`,
        type: RoleType.SPECIALTY_DANCER,
        performerType: PerformerType.ON_CAMERA_GROUP,
        scriptId: scriptId,
      };
      const role = await roleStore.createRole(roleRequest);
      roles && role && roles.push(role);
    }
    const roleGroupRequest = {
      name: 'New Group',
      scriptId: scriptId,
      projectId: projectStore.currentProject?.id || '',
      roleIds: roles.map((r) => r.id),
    };
    const roleGroup = await roleStore.createRoleGroup(roleGroupRequest);
    roleGroups &&
      roleGroup &&
      setRoleGroups([
        ...roleGroups,
        {
          ...roleGroup,
          members: roleGroup.roles.length.toString(),
          performer_type: roleGroup.roles[0].performer_type,
          type: roleGroup.roles[0].type,
        },
      ]);
  };

  const deleteRoleGroups = async (ids: string[]) => {
    for (const id of ids) await roleStore.deleteRoleGroup(id);
    setRoleGroups(roleGroups.filter((r) => !ids.includes(r.id)));
  };

  const updateRoleGroup = async (update: ExtendedRoleGroup, index: number) => {
    if (!scriptId) return;
    if (update.performer_type !== roleGroups[index].performer_type) {
      for (const role of update.roles) {
        role.performer_type = update.performer_type;
        await roleStore.updateRole(role);
      }
    } else if (update.type && update.type !== roleGroups[index].type) {
      for (const role of update.roles) {
        role.type = update.type;
        await roleStore.updateRole(role);
      }
    } else if (update.members && update.members !== roleGroups[index].members) {
      // we need to add or remove roles from the group
      const members = parseInt(update.members);
      if (members > update.roles.length) {
        // add roles
        const roleRequest = {
          name: '',
          roleGroupId: update.id,
          type: RoleType.SPECIALTY_DANCER,
          performerType: PerformerType.ON_CAMERA_GROUP,
          scriptId: scriptId,
        };
        for (let i = 0; i < members - update.roles.length; i++) {
          roleRequest.name = `New Group Role ${update.roles.length + i + 1}`;
          const role = await roleStore.createRole(roleRequest);
          role && roleGroups[index].roles.push(role);
        }
      } else if (members < update.roles.length) {
        // remove roles
        for (let i = 0; i < update.roles.length - members; i++) {
          const role = update.roles.pop();
          role && (await roleStore.deleteRole(role.id));
        }
      }
    } else {
      const roleGroup = await roleStore.updateRoleGroup(update);
      if (!roleGroup) return;
      const newRoleGroups = [...roleGroups];
      newRoleGroups[index] = roleGroup;
      setRoleGroups(
        newRoleGroups?.map((rg) => ({
          ...rg,
          members: rg.roles.length.toString(),
          performer_type: rg.roles[0].performer_type,
          type: rg.roles[0].type,
        }))
      );
    }
  };

  return (
    <Panel
      items={roleGroups}
      newItem={newRoleGroup}
      deleteItems={deleteRoleGroups}
      updateItem={updateRoleGroup}
      label="Performer Group"
      labelPlural="Performer Groups"
      addIcon={<Group />}
      types={[
        { label: 'Number Of Members', key: 'members', values: ['3', '4', '5', '6', '7', '8', '9'] },
        {
          label: 'Performer Type',
          key: 'performer_type',
          values: Object.values(PerformerType).filter((v) => v.includes('Group')),
        },
        {
          label: 'Role Type',
          key: 'type',
          values: Object.values(RoleType),
          filterKey: 'performer_type',
          filterFunction: filterRoleTypes,
        },
      ]}
    />
  );
});
