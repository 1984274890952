import { Box, CheckBox, DateInput, Select, Text } from 'grommet';
import { Yoga } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SessionActivityRow } from '.';
import { AddNewButton, TalonTip } from '../..';

import {
  Costume,
  Location,
  Prop,
  Role,
  RoleGroup,
  Script,
  Session,
  SessionActivity,
  SessionActivityType,
} from '/lib/api';
import {
  useCostumeStore,
  useEstimateStore,
  useProjectStore,
  usePropStore,
  useRoleStore,
  useSessionActivityStore,
  useSessionStore,
} from '/src/context';
import dayjs from 'dayjs';

export interface ISessionRowProps {
  sessionId: string;
  onChecked: () => void;
  checked: boolean;
  locations: Location[];
}

export const SessionRow = observer(({ sessionId, locations, onChecked, checked }: ISessionRowProps) => {
  const projectStore = useProjectStore();
  const sessionStore = useSessionStore();
  const costumeStore = useCostumeStore();
  const estimateStore = useEstimateStore();
  const propStore = usePropStore();
  const roleStore = useRoleStore();
  const sessionActivityStore = useSessionActivityStore();

  const [scripts, setScripts] = useState<Script[]>([]);
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [selectedScript, setSelectedScript] = useState<Script | undefined>(undefined);
  const [costumes, setCostumes] = useState<Costume[]>([]);
  const [props, setProps] = useState<Prop[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleGroups, setRoleGroups] = useState<RoleGroup[]>([]);

  const sortSessionActivities = (s: Session) => {
    if (!s || !s.activities) {
      console.log('session/activites not set, cant sort');
      return;
    }
    s?.activities?.sort((a, b) => {
      if (new Date(a.start_time) < new Date(b.start_time)) {
        return -1;
      }
      if (new Date(a.start_time) > new Date(b.start_time)) {
        return 1;
      }
      return 0;
    });
    return s;
  };

  const addSessionActivity = async () => {
    if (!session || !selectedScript) return;
    const previousActivity: SessionActivity | undefined = session.activities[session.activities.length - 1];
    const startTime = dayjs(previousActivity?.end_time ?? `${session.date}T09:00:00`).toISOString();
    const endTime = previousActivity
      ? dayjs(previousActivity.end_time).add(1, 'hour').toISOString()
      : new Date(`${session.date}T17:00:00`).toISOString();
    const sessionActivity = await sessionActivityStore.createSessionActivity(
      session.id,
      SessionActivityType.WORK_TIME,
      startTime,
      endTime,
      selectedScript.id
    );
    if (sessionActivity) {
      const activities = [...session.activities, sessionActivity];
      const updatedSession = { ...session, activities };

      const sessionEstimate = (await sessionStore.getSession(session.id))?.session_estimate;
      if (sessionEstimate) updatedSession.session_estimate = sessionEstimate;

      setSession(sortSessionActivities(updatedSession));
    }
  };

  const updateSessionDate = async (update: Session) => {
    console.log('updateSessionDate', update);
    await sessionStore.updateSession(update);
    const updatedSession = await sessionStore.getSession(update.id);
    updatedSession && setSession(sortSessionActivities(updatedSession));
  };

  const updateSessionActivity = async (update: SessionActivity) => {
    if (!session) return;
    const sa = await sessionActivityStore.updateSessionActivity(update);
    if (!sa) return;

    const activities = session.activities?.map((a) => (a.id === sa.id ? sa : a));
    const updatedSession = { ...session, activities };

    const sessionEstimate = (await sessionStore.getSession(session.id))?.session_estimate;
    if (sessionEstimate) updatedSession.session_estimate = sessionEstimate;

    setSession(sortSessionActivities(updatedSession));
  };

  const deleteSessionActivity = async (activityId: string) => {
    if (!session) return;
    await sessionActivityStore.deleteSessionActivity(activityId);

    const activities = session?.activities?.filter((a) => a.id !== activityId);
    const updatedSession = { ...session, activities };

    const sessionEstimate = (await sessionStore.getSession(session.id))?.session_estimate;
    if (sessionEstimate) {
      updatedSession.session_estimate = sessionEstimate;
      await estimateStore.refreshEstimateTotals(sessionEstimate.estimate_id);
    }

    setSession(sortSessionActivities(updatedSession));
  };

  useEffect(() => {
    const getScripts = async () => {
      if (!projectStore.currentProject) return;
      const s = await projectStore.getScriptsByProject(projectStore.currentProject.id);
      setScripts(s || []);
    };
    getScripts();
  }, []);

  useEffect(() => {
    const getSession = async () => {
      const s = await sessionStore.getSession(sessionId);

      s && setSession(sortSessionActivities(s));
      console.log('Session Estimate:', s?.session_estimate);
      s?.activities?.length && setSelectedScript(s.activities[0].script);
    };
    getSession();
  }, [sessionId]);

  useEffect(() => {
    const getDropDownOptions = async () => {
      if (!selectedScript?.id) return;
      const r = await roleStore.getRolesByScriptId(selectedScript.id);
      const rg = await roleStore.getRoleGroupsByScriptId(selectedScript.id);
      const c = await costumeStore.getCostumesByScriptId(selectedScript.id);
      const p = await propStore.getPropsByScriptId(selectedScript.id);
      setRoles(r || []);
      setRoleGroups(rg || []);
      setCostumes(c || []);
      setProps(p || []);
    };
    getDropDownOptions();
  }, [selectedScript]);

  return (
    <Box direction="column">
      <Box direction="row" align="start" background="light-2">
        <Box direction="row" gap="small" pad="small">
          <CheckBox onChange={onChecked} checked={checked} />
          <DateInput
            value={session && new Date(`${session.date}T00:00:00`).toISOString()}
            format="mm/dd/yyyy"
            onChange={(event) =>
              session && typeof event.value === 'string' && updateSessionDate({ ...session, date: event.value })
            }
          />
        </Box>
        <Box direction="row" gap="small" pad="small" alignSelf="center">
          {!selectedScript && (
            <Select
              options={scripts}
              value={selectedScript}
              valueKey="id"
              labelKey="name"
              onChange={({ value: script }) => setSelectedScript(script)}
              placeholder="Choose script..."
              size="medium"
            />
          )}
          {selectedScript && <Text>{selectedScript.name}</Text>}
        </Box>
        <Box direction="row" gap="small" pad="small" align="end">
          <AddNewButton
            icon={
              <TalonTip content={<Text>Add An Activity</Text>}>
                <Yoga />
              </TalonTip>
            }
            onClick={() => addSessionActivity()}
            disabled={!selectedScript?.id}
          />
        </Box>
      </Box>
      <Box direction="column" gap="small">
        {session?.activities?.map((activity) => (
          <SessionActivityRow
            script={selectedScript}
            session={session}
            costumes={costumes}
            props={props}
            roles={roles}
            roleGroups={roleGroups}
            locations={locations}
            deleteSessionActivity={deleteSessionActivity}
            activity={activity}
            updateSessionActivity={(update) => updateSessionActivity({ ...activity, ...update })}
            key={activity.id}
            sessionEstimate={session.session_estimate}
          />
        ))}
      </Box>
    </Box>
  );
});
