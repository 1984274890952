import React from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { ChatOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { Conversation } from '/lib/api';
import { AddNewButton, Delete, TalonTip } from '..';

export interface IConversationListProps {
  conversations: Conversation[];
  selectConversation: (id: string) => void;
  deleteConversation: (id: string) => Promise<void>;
  setNewChat: () => void;
  gridArea?: string;
}

export const ConversationList = observer(
  ({ conversations, selectConversation, deleteConversation, setNewChat, gridArea }: IConversationListProps) => {
    return (
      <Box
        direction="column"
        pad="medium"
        overflow={{ vertical: 'auto' }}
        align="start"
        id="conversation-list"
        gridArea={gridArea}
      >
        <Box direction="row">
          <Heading level="3" margin="medium">
            Conversations
          </Heading>
          <AddNewButton onClick={setNewChat} tipText="New Conversation" icon={<ChatOption />} />
        </Box>

        {conversations.map((c) => (
          <Box direction="row" key={c.id}>
            <TalonTip content={<Text>{c.name}</Text>}>
              <Button
                pad="small"
                margin="small"
                hoverIndicator
                onClick={() => selectConversation(c.id)}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  textOverflow: 'ellipsis',
                }}
                label={c.name.length > 35 ? `${c.name.slice(0, 34)}...` : c.name}
              />
            </TalonTip>
            <Delete onClick={() => deleteConversation(c.id)} name={c.name} />
          </Box>
        ))}
        {!conversations.length && (
          <Text>
            No previous conversations found for this project. Once you've chatted with Talon AI, those prior chats for
            the selected project will be listed here.
          </Text>
        )}
      </Box>
    );
  }
);
