import { useState, useEffect } from 'react';
import { Box, Button, Keyboard, Text, Calendar, MaskedInput, DropButton } from 'grommet';
import { Schedule } from 'grommet-icons';
import { TalonTip } from '.';

interface IDropContentProps {
  date: string;
  time: string;
  onClose: (date: string, time: string) => void;
  bounds?: string[];
  disabled?: string[][];
}

const DropContent = ({ date: initialDate, time: initialTime, onClose, bounds, disabled }: IDropContentProps) => {
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [isTimeValid, setIsTimeValid] = useState<boolean>(false);

  const close = () => onClose(date || initialDate, time || initialTime);
  const validateAndSetTime = (t: string) => {
    const h = parseInt(t.split(':')[0], 10);
    const m = parseInt(t.split(':')[1], 10);
    const ampm = t.split(' ')[1];
    setIsTimeValid(h >= 1 && h <= 12 && m >= 0 && m <= 59 && (ampm === 'am' || ampm === 'pm'));
    setTime(t);
  };
  return (
    <Box align="center">
      <Calendar
        animate={false}
        date={date || initialDate}
        onSelect={(d) => (!Array.isArray(d) ? setDate(new Date(d).toISOString()) : undefined)}
        showAdjacentDays={false}
        locale="en-US"
        size="small"
        bounds={bounds}
        disabled={disabled}
      />
      <Box flex={false} pad="small" gap="small">
        <Keyboard
          onEnter={(event) => {
            event.preventDefault(); // so drop doesn't re-open
            close();
          }}
        >
          <MaskedInput
            mask={[
              {
                length: [1, 2],
                options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                regexp: /^1[1-2]$|^[0-9]$/,
                placeholder: 'hh',
                restrictToOptions: true,
              },
              { fixed: ':' },
              {
                length: 2,
                options: ['00', '15', '30', '45'],
                regexp: /^[0-5][0-9]$|^[0-9]$/,
                placeholder: 'mm',
              },
              { fixed: ' ' },
              {
                length: 2,
                options: ['am', 'pm'],
                regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                placeholder: 'am/pm',
              },
            ]}
            value={time || initialTime}
            name="maskedInput"
            onChange={(event) => validateAndSetTime(event.target.value)}
          />
        </Keyboard>
        <Box flex={false}>
          <Button label="Done" onClick={close} disabled={!isTimeValid} />
        </Box>
      </Box>
    </Box>
  );
};

export interface IDateTimeDropButtonProps {
  placeholder?: string;
  date?: string;
  time?: string;
  onChange: (dateTime: Date) => void;
  bounds?: string[];
  disabled?: string[][];
}

export const DateTimeDropButton = ({
  placeholder,
  date: initialDate,
  time: initialTime,
  onChange,
  bounds,
  disabled,
}: IDateTimeDropButtonProps) => {
  const [date, setDate] = useState<string>(initialDate || '');
  const [time, setTime] = useState<string>(initialTime || '');
  const [open, setOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(placeholder || 'Select date & time');

  const onClose = (nextDate: string, nextTime: string) => {
    setDate(nextDate);
    setTime(nextTime);
    setOpen(false);
    setTimeout(() => setOpen(false), 1);
    const d = new Date(nextDate);
    const h = parseInt(nextTime.split(':')[0], 10);
    const m = parseInt(nextTime.split(':')[1], 10);
    const ampm = nextTime.split(' ')[1];
    if (ampm === 'pm' && h < 12) d.setHours(h + 12);
    else if (ampm === 'am' && h === 12) d.setHours(0);
    else d.setHours(h);
    d.setMinutes(m);
    onChange(d);
  };

  useEffect(() => {
    if (date && time) {
      if (date === initialDate) {
        setLabel(time);
      } else {
        setLabel(`${new Date(date).toLocaleDateString()} ${time}`);
      }
    } else {
      setLabel(placeholder || 'Select date & time');
    }
  }, [date, time, initialDate, placeholder]);

  return (
    <Box align="center" pad="small">
      <DropButton
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropContent={<DropContent date={date} time={time} onClose={onClose} bounds={bounds} disabled={disabled} />}
      >
        <Box direction="row" gap="medium" align="center" pad="small">
          <Text color={date ? undefined : 'dark-2'}>{label}</Text>
          <TalonTip content={<Text>{placeholder}</Text>}>
            <Schedule />
          </TalonTip>
        </Box>
      </DropButton>
    </Box>
  );
};
