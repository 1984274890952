import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Session, SessionService } from '/lib/api';

export class SessionStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  listSessions = async (shootId: string | undefined) => {
    if (!shootId) return;
    try {
      const { sessions } = await SessionService.listSessions(shootId);
      return sessions;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSession = async (sessionId: string) => {
    try {
      const { session } = await SessionService.getSession(sessionId, 'activities,session_estimate');
      const a = await this.rootStore.sessionActivityStore.listSessionActivities(sessionId);
      if (session && a) {
        session.activities = a;
      }
      return session;
    } catch (e) {
      this.handleError(e);
    }
  };

  createSession = async (date: string, shootId: string) => {
    if (!shootId || !date) return;
    try {
      const { session } = await SessionService.createSession({ date, shootId });
      return session;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSession = async (updatedSession: Session) => {
    try {
      const { session } = await SessionService.updateSession(updatedSession.id, updatedSession);
      //   if (!session || !this.rootStore.estimateStore.currentEstimate?.id || !session.project_id) return;
      return session;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSession = async (sessionId: string) => {
    try {
      await SessionService.deleteSession(sessionId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
