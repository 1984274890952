/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { Box, Text, TextInput } from 'grommet';
import { WidthType } from 'grommet/utils';
import { useDebounce } from '../../utils/useDebounce';
import { TalonTip } from '.';

export interface IEditableTextProps {
  val: any;
  onChange: (e: any) => void;
  width?: WidthType;
  textSize?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  textMargin?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  inputType?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url';
  shortenTo?: number;
}

export const EditableText = ({
  val,
  onChange,
  width,
  textSize,
  textMargin,
  inputType,
  shortenTo,
}: IEditableTextProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<any>(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  const debouncedRequest = useDebounce(() => {
    onChange(value);
  });

  const doChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);

    debouncedRequest();
  };

  const takeFocus = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setEdit(true);
  };

  return edit ? (
    <Box width={width} margin={textMargin} alignSelf="start">
      <TextInput
        value={value}
        onChange={doChange}
        onKeyDown={(e) => {
          e.key === 'Enter' && setEdit(!edit);
        }}
        onBlur={() => setEdit(!edit)}
        size={textSize}
        plain="full"
        type={inputType}
        style={{ backgroundColor: 'accent-3' }}
        onClick={(e) => takeFocus(e)}
      />
    </Box>
  ) : (
    <Box width={width} border={{ side: 'bottom', color: 'accent-3' }} margin={textMargin} alignSelf="start">
      {shortenTo ? (
        <TalonTip content={<Text>{value}</Text>}>
          <Text style={{ cursor: 'text' }} onClick={(e) => takeFocus(e)} size={textSize}>
            {value.length > shortenTo ? `${value.slice(0, shortenTo - 1)}...` : value}
          </Text>
        </TalonTip>
      ) : (
        <Text style={{ cursor: 'text' }} onClick={(e) => takeFocus(e)} size={textSize}>
          {value}
        </Text>
      )}
    </Box>
  );
};
