import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  Estimate,
  EstimateService,
  SessionEstimateService,
  TCreateEstimateRequest,
  TEstimateId,
  TProjectId,
  TSessionEstimateId,
  TUpdateEstimateRequest,
} from '/lib/api';

export class EstimateStore extends BaseStore {
  currentEstimate?: Estimate = {} as Estimate;
  estimateList: Estimate[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentEstimate: observable,
      estimateList: observable,
      setCurrentEstimate: action,
    });
  }

  setCurrentEstimate(estimate?: Estimate) {
    this.currentEstimate = estimate;
  }

  listEstimates = async (projectId: TProjectId, relations?: string) => {
    if (!projectId) return;
    // TODO implement pagination
    try {
      const { estimates } = await EstimateService.listEstimates(projectId, undefined, undefined, undefined, relations);
      runInAction(() => {
        this.estimateList = estimates;
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  getEstimate = async (estimateId?: TEstimateId, relations?: string) => {
    if (!estimateId) return;
    try {
      const { estimate } = await EstimateService.getEstimate(estimateId, relations);
      return estimate;
    } catch (e) {
      this.handleError(e);
    }
  };

  getEstimateTotals = async (estimateId?: TEstimateId) => {
    if (!estimateId) return;
    try {
      const totals = await EstimateService.getEstimateTotals(estimateId);
      return totals;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSessionEstimateSubtotals = async (sessionEstimateId?: TSessionEstimateId) => {
    if (!sessionEstimateId) return;
    try {
      const totals = await SessionEstimateService.getSessionEstimateSubtotals(sessionEstimateId);
      return totals;
    } catch (e) {
      this.handleError(e);
    }
  };

  refreshEstimateTotals = async (estimateId?: TEstimateId) => {
    if (!estimateId || !this.currentEstimate || this.currentEstimate.id !== estimateId) return;
    try {
      const totals = await this.getEstimateTotals(estimateId);
      if (totals && this.currentEstimate) {
        this.setCurrentEstimate({
          ...this.currentEstimate,
          total: totals.total,
          total_plus_fringe: totals.total_plus_fringe,
        });
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  refreshEstimate = async (estimateId?: TEstimateId) => {
    if (!estimateId) return;
    try {
      const estimate = await this.getEstimate(estimateId, 'shoot');
      this.setCurrentEstimate(estimate ?? undefined);
    } catch (e) {
      this.handleError(e);
    }
  };

  createEstimate = async (projectId: TProjectId, title: string, shootId?: string) => {
    if (!projectId) return;
    // if there's an existing shoot for this project, use that shoot.
    let shootIdToUse: string;
    if (!shootId) {
      try {
        const shoot = await this.rootStore.shootStore.createShoot(projectId);
        shootIdToUse = shoot?.id || '';
      } catch (e) {
        this.handleError(e);
        return;
      }
    } else {
      shootIdToUse = shootId;
    }
    // create a new estimate
    const createEstimateRequest: TCreateEstimateRequest = {
      title,
      shootId: shootIdToUse,
    };
    try {
      const { estimate } = await EstimateService.createEstimate(createEstimateRequest);
      runInAction(() => {
        this.estimateList?.push(estimate);
      });
      return estimate;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateEstimate = async (e: Estimate) => {
    const i = this.estimateList?.findIndex((p) => p.id === e.id);
    if (i === undefined || i === -1) return;
    const updateRequest: TUpdateEstimateRequest = {
      status: e.status,
      title: e.title,
      // version: e.version,
      // sessionEstimateIds: e.session_estimates?.map((se) => se.id),
      shootId: e.shoot?.id,
    };
    try {
      const { estimate: updatedEstimate } = await EstimateService.updateEstimate(e.id, updateRequest);
      console.log('updatedEstimate: %O', updatedEstimate);
      runInAction(() => {
        if (updatedEstimate) {
          this.estimateList.splice(i, 1, updatedEstimate);
          this.setCurrentEstimate(updatedEstimate);
        }
      });
      return updatedEstimate;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteEstimate = async (estimateId: string) => {
    try {
      await EstimateService.deleteEstimate(estimateId);
      if (estimateId === this.currentEstimate?.id) this.setCurrentEstimate(undefined);
      runInAction(() => {
        this.estimateList = this.estimateList?.filter((p) => p.id !== estimateId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };
}
