import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  Role,
  RoleGroup,
  RoleGroupService,
  RoleService,
  TCreateRoleGroupRequest,
  TCreateRoleRequest,
  TUpdateRoleGroupRequest,
  TUpdateRoleRequest,
} from '/lib/api';

export class RoleStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  /**
   * Roles
   */

  getRolesByScriptId = async (scriptId: string) => {
    try {
      const { roles } = await RoleService.listRoles(scriptId, undefined, undefined, undefined, 'role_group');
      return roles;
    } catch (e) {
      this.handleError(e);
    }
  };

  createRole = async ({ name, type, performerType, scriptId }: TCreateRoleRequest) => {
    try {
      const { role } = await RoleService.createRole({ name, type, performerType, scriptId });
      return role;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateRole = async (role: Role) => {
    const updateRequest: TUpdateRoleRequest = {
      ...role,
      performerType: role.performer_type,
      scriptId: role.script?.id,
      roleGroupId: role.role_group?.id,
      sessionActivityIds: role.session_activities?.map((sa) => sa.id),
      allowanceIds: role.allowances?.map((a) => a.id),
      costumeIds: role.costumes?.map((c) => c.id),
      propIds: role.props?.map((p) => p.id),
      feeIds: role.fees?.map((f) => f.id),
      tagsIds: role.tags?.map((t) => t.id),
      usageIds: role.usages?.map((u) => u.id),
      roleActivityIds: role.role_activities?.map((ra) => ra.id),
    };
    try {
      const { role: updatedRole } = await RoleService.updateRole(role.id, updateRequest);
      return updatedRole;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteRole = async (roleId: string) => {
    try {
      await RoleService.deleteRole(roleId);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * Role Groups
   */

  getRoleGroupsByScriptId = async (scriptId: string) => {
    try {
      const { roleGroups } = await RoleGroupService.listRoleGroups(scriptId, undefined, undefined, undefined, 'roles');
      return roleGroups;
    } catch (e) {
      this.handleError(e);
    }
  };

  createRoleGroup = async ({ name, roleIds, scriptId, projectId, sessionActivityIds }: TCreateRoleGroupRequest) => {
    try {
      const { roleGroup } = await RoleGroupService.createRoleGroup({
        name,
        scriptId,
        roleIds,
        projectId,
        sessionActivityIds,
      });
      return roleGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateRoleGroup = async (roleGroup: RoleGroup) => {
    const updateRequest: TUpdateRoleGroupRequest = {
      ...roleGroup,
      scriptId: roleGroup.script?.id,
      roleIds: roleGroup.roles?.map((r) => r.id),
      sessionActivityIds: roleGroup.session_activities?.map((sa) => sa.id),
    };
    try {
      const { roleGroup: updatedRoleGroup } = await RoleGroupService.updateRoleGroup(roleGroup.id, updateRequest);
      return updatedRoleGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteRoleGroup = async (roleGroupId: string) => {
    try {
      await RoleGroupService.deleteRoleGroup(roleGroupId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
