/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enums
 */
export enum DocumentType {
    SCRIPT = 'Script',
    STORYBOARD = 'Storyboard',
    SHOOT_SCHEDULE = 'Shoot Schedule',
    MEDIA_PLAN = 'Media Plan',
    CONTRACT = 'Contract',
    GENERIC = 'Generic',
}
