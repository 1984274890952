/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootStore } from '.';
import { CancelablePromise } from '/lib/api';

export abstract class BaseStore {
  rootStore: RootStore;
  q: (request: CancelablePromise<any>) => Promise<any>;
  handleError: (e: any, customMsg?: string) => void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.q = this.rootStore.addRequest;
    this.handleError = this.rootStore.handleError;
  }
}
