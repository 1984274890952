import {
  User,
  AuthenticationService,
  TCreateUserRequest,
  TLoginRequest,
  OpenAPI,
  UserService,
  TCreateGroupRequest,
  ApiError,
} from '/lib/api';
import { action, makeObservable, observable, computed, runInAction, toJS } from 'mobx';
import Storage from '/src/utils/storage';
import * as jwt from 'jsonwebtoken';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { config } from '../../config';

export class UserStore extends BaseStore {
  _user: User;
  _jwt: string;
  constructor(root: RootStore) {
    super(root);
    this._user = {} as User;
    this._jwt = '';
    if (this.token) {
      OpenAPI.TOKEN = this.token;
    }
    makeObservable(this, {
      _user: observable,
      _jwt: observable,
      token: computed,
      user: computed,
      restoreFromStorage: action,
      logout: action,
      confirmEmail: action,
    });
  }

  restoreFromStorage = async () => {
    const token = Storage.getToken();
    if (token) {
      this._jwt = OpenAPI.TOKEN = token;
      const JwtUser = jwt.decode(token || '', { json: true })?.data as User;
      try {
        const { user } = await this.q(UserService.getUser(JwtUser.id, 'groups,active_group'));
        runInAction(() => {
          this._user = user ? user : ({} as User);
        });
      } catch (e) {
        this.handleError(e);
        this.logout();
      }
    }
  };

  get token(): string {
    if (!this._jwt) {
      // the user has refreshed the page
      // get the jwt token from storage
      // and set the jwt token
      this.restoreFromStorage();
    }
    return this._jwt;
  }

  get user(): User {
    if (!this._user?.id) {
      // the user has refreshed the page
      this.restoreFromStorage();
    }
    return toJS(this._user);
  }
  public signup = async ({
    user,
    group,
    token,
  }: {
    user: TCreateUserRequest;
    group: TCreateGroupRequest;
    token?: string;
  }) => {
    const req = {
      user,
      group,
      token,
    };
    try {
      return await this.q(UserService.signUp(req));
    } catch (e) {
      this.handleError(e, config.toast.signup.error(e as ApiError));
    }
  };

  public resendConfirmationEmail = async () => {
    try {
      await this.q(UserService.resendConfirmationEmail());
    } catch (e) {
      this.handleError(e);
    }
  };

  public confirmEmail = async (t: string) => {
    try {
      const { jwt, user } = await this.q(UserService.confirmEmail({ token: t }));
      runInAction(() => {
        this._user = user;
        this._jwt = OpenAPI.TOKEN = jwt;
        Storage.setToken(jwt);
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  public login = async (req: TLoginRequest) => {
    try {
      const res = await this.q(AuthenticationService.login(req));
      runInAction(() => {
        this._user = res.user;
        this._jwt = OpenAPI.TOKEN = res.jwt;
        Storage.setToken(res.jwt);
      });
      return true;
    } catch (e) {
      this.handleError(e, config.toast.login.error(e as ApiError));
      return false;
    }
  };

  public logout = () => {
    this._user = {} as User;
    this._jwt = '';
    Storage.removeLogInInfo();
    OpenAPI.TOKEN = '';
  };

  public updateProfile = async (profile: User) => {
    try {
      const { user } = await this.q(UserService.updateUser(profile.id, profile));
      runInAction(() => {
        this._user = { ...this._user, ...user };
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  public changeActiveGroup = async (groupId: string) => {
    try {
      const { group } = await this.q(
        UserService.changeActiveGroup(this.user.id, { group_id: groupId })
      );
      runInAction(() => {
        this._user = { ...this._user, active_group: group };
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  public changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await this.q(UserService.changePassword(this.user.id, { old_password: oldPassword, new_password: newPassword }));
      return true;
    } catch (e) {
      this.handleError(e);
    }
  };

  // forgot password
  public forgotPassword = async (email: string) => {
    try {
      await this.q(UserService.forgotPassword({ email }));
      return true;
    } catch (e) {
      this.handleError(e);
    }
    return false;
  };

  public validateResetPasswordToken = async (token: string) => {
    try {
      const { user } = await this.q(UserService.validatePasswordResetToken(token));
      return user.id;
    } catch (e) {
      this.handleError(e);
    }
  };

  public resetPassword = async (user_id: string, token: string, password: string) => {
    try {
      return await this.q(UserService.resetPassword({ user_id, token, password }));
    } catch (e) {
      this.handleError(e);
    }
  };
}
