/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateSubscriptionPlanRequest } from '../models/TCreateSubscriptionPlanRequest';
import type { TCreateSubscriptionPlanResponse } from '../models/TCreateSubscriptionPlanResponse';
import type { TGetSubscriptionPlanResponse } from '../models/TGetSubscriptionPlanResponse';
import type { TListSubscriptionPlansResponse } from '../models/TListSubscriptionPlansResponse';
import type { TSubscriptionPlanId } from '../models/TSubscriptionPlanId';
import type { TUpdateSubscriptionPlanRequest } from '../models/TUpdateSubscriptionPlanRequest';
import type { TUpdateSubscriptionPlanResponse } from '../models/TUpdateSubscriptionPlanResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionPlanService {

    /**
     * Get Subscription Plan
     * @param id
     * @param relations
     * @returns TGetSubscriptionPlanResponse TGetSubscriptionPlanResponse
     * @throws ApiError
     */
    public static getSubscriptionPlan(
        id: TSubscriptionPlanId,
        relations?: string,
    ): CancelablePromise<TGetSubscriptionPlanResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription-plans/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Subscription Plan
     * @param id
     * @param requestBody
     * @returns TUpdateSubscriptionPlanResponse TUpdateSubscriptionPlanResponse
     * @throws ApiError
     */
    public static updateSubscriptionPlan(
        id: TSubscriptionPlanId,
        requestBody: TUpdateSubscriptionPlanRequest,
    ): CancelablePromise<TUpdateSubscriptionPlanResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/subscription-plans/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Subscription Plan
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSubscriptionPlan(
        id: TSubscriptionPlanId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subscription-plans/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Subscription Plans
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListSubscriptionPlansResponse TListSubscriptionPlansResponse
     * @throws ApiError
     */
    public static listSubscriptionPlans(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListSubscriptionPlansResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription-plans',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Subscription Plan
     * @param requestBody
     * @returns TCreateSubscriptionPlanResponse TCreateSubscriptionPlanResponse
     * @throws ApiError
     */
    public static createSubscriptionPlan(
        requestBody: TCreateSubscriptionPlanRequest,
    ): CancelablePromise<TCreateSubscriptionPlanResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscription-plans',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
