import {
  Feature,
  FeatureService,
  Group,
  GroupService,
  PricingTier,
  PricingTierService,
  SubscriptionPlan,
  SubscriptionPlanService,
  User,
  UserService,
} from '/lib/api';
import { makeObservable} from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually

export class AdminStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {});
  }

  // SUBSCRIPTION PLANS
  fetchSubscriptionPlans = async () => {
    try {
      const { subscriptionPlans } = await this.q(
        SubscriptionPlanService.listSubscriptionPlans(undefined, undefined, undefined, 'groups,pricing_tier,features')
      );
      return subscriptionPlans;
    } catch (e) {
      this.handleError(e);
    }
  };

  createSubscriptionPlan = async ({ name = 'New Subscription Plan', pricing_tier }: Partial<SubscriptionPlan>) => {
    if (!pricing_tier) {
      throw new Error('Pricing tier is required');
    }
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.createSubscriptionPlan({
          name,
          pricing_tier_id: pricing_tier.id,
        })
      );
      return subscriptionPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSubscriptionPlan = async (id: string) => {
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.getSubscriptionPlan(id, 'groups,pricing_tier,features')
      );
      return subscriptionPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSubscriptionPlan = async (request: SubscriptionPlan) => {
    if (!request.name || !request.pricing_tier) {
      throw new Error('Name and price are required');
    }
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.updateSubscriptionPlan(request.id, {
          name: request.name,
          pricing_tier_id: request.pricing_tier.id,
          group_ids: request.groups?.map((g) => g.id),
          feature_ids: request.features?.map((f) => f.id),
        })
      );
      const updatedPlan = await this.getSubscriptionPlan(subscriptionPlan.id);
      return updatedPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSubscriptionPlan = async (id: string) => {
    try {
      await this.q(SubscriptionPlanService.deleteSubscriptionPlan(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // PRICING TIERS
  fetchPricingTiers = async () => {
    try {
      const { pricingTiers } = await this.q(
        PricingTierService.listPricingTiers(undefined, undefined, undefined, 'subscription_plans')
      );
      return pricingTiers;
    } catch (e) {
      this.handleError(e);
    }
  };

  createPricingTier = async ({ name = 'New Pricing Tier', price = 0 }: Partial<PricingTier>) => {
    if (isNaN(price)) {
      throw new Error('Price is required');
    }
    try {
      const { pricingTier } = await this.q(PricingTierService.createPricingTier({ name, price }));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  getPricingTier = async (id: string) => {
    try {
      const { pricingTier } = await this.q(PricingTierService.getPricingTier(id, 'subscription_plans'));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  updatePricingTier = async ({ id, name, price, subscription_plans }: Partial<PricingTier>) => {
    if (!id || !name || (price && isNaN(price))) {
      console.log('id, name, price', id, name, price);
      throw new Error('Name and price are required');
    }
    const request = {
      name,
      price,
      subscription_plan_ids: subscription_plans?.map((p) => p.id),
    };
    try {
      await this.q(PricingTierService.updatePricingTier(id, request));
      const { pricingTier } = await this.q(PricingTierService.getPricingTier(id, 'subscription_plans'));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  deletePricingTier = async (id: string) => {
    try {
      await this.q(PricingTierService.deletePricingTier(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // FEATURES
  fetchFeatures = async () => {
    try {
      const { features } = await this.q(FeatureService.listFeatures());
      return features;
    } catch (e) {
      this.handleError(e);
    }
  };

  createFeature = async ({ name = 'New Feature' }: Partial<Feature>) => {
    try {
      const { feature } = await this.q(FeatureService.createFeature({ name }));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  getFeature = async (id: string) => {
    try {
      const { feature } = await this.q(FeatureService.getFeature(id));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateFeature = async ({ id, name }: Partial<Feature>) => {
    if (!id || !name) {
      throw new Error('Feature name is required');
    }
    try {
      const { feature } = await this.q(FeatureService.updateFeature(id, { name }));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteFeature = async (id: string) => {
    try {
      await this.q(FeatureService.deleteFeature(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // USERS
  fetchUsers = async () => {
    try {
      const { users } = await this.q(UserService.listUsers());
      return users;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateUser = async (user: User) => {
    try {
      const { user: updatedUser } = await this.q(UserService.updateUser(user.id, user));
      return updatedUser;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteUser = async (id: string) => {
    try {
      await this.q(UserService.deleteUser(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // GROUPS
  fetchGroups = async () => {
    try {
      const { groups } = await this.q(GroupService.listGroups());
      return groups;
    } catch (e) {
      this.handleError(e);
    }
  };

  createGroup = async ({ name, subscription_plan }: Partial<Group>) => {
    if (!name || !subscription_plan) {
      throw new Error('Name and subscription plan are required');
    }
    const request = {
      name,
      subscription_plan_id: subscription_plan.id,
    };
    try {
      const { group: newGroup } = await this.q(GroupService.createGroup(request));
      return newGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateGroup = async (group: Group) => {
    try {
      const { group: updatedGroup } = await this.q(GroupService.updateGroup(group.id, group));
      return updatedGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteGroup = async (id: string) => {
    try {
      await this.q(GroupService.deleteGroup(id));
    } catch (e) {
      this.handleError(e);
    }
  };
}
