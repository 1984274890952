/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Estimate Unit Types
 */
export enum SessionEstimateItemUnitType {
    HOURS = 'hours',
    MILES = 'miles',
    DAYS = 'days',
    COUNT = 'count',
    COSTUME_CHANGES = 'costume changes',
    TAGS = 'tags',
    DOUBLES = 'doubles',
}
