/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateSessionActivityRequest } from '../models/TCreateSessionActivityRequest';
import type { TCreateSessionActivityResponse } from '../models/TCreateSessionActivityResponse';
import type { TGetSessionActivityResponse } from '../models/TGetSessionActivityResponse';
import type { TListSessionActivitiesResponse } from '../models/TListSessionActivitiesResponse';
import type { TSessionActivityId } from '../models/TSessionActivityId';
import type { TUpdateSessionActivityRequest } from '../models/TUpdateSessionActivityRequest';
import type { TUpdateSessionActivityResponse } from '../models/TUpdateSessionActivityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionActivityService {

    /**
     * Get SessionActivity
     * @param id
     * @param relations
     * @returns TGetSessionActivityResponse TGetSessionActivityResponse
     * @throws ApiError
     */
    public static getSessionActivity(
        id: TSessionActivityId,
        relations?: string,
    ): CancelablePromise<TGetSessionActivityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-activities/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update SessionActivity
     * @param id
     * @param requestBody
     * @returns TUpdateSessionActivityResponse TUpdateSessionActivityResponse
     * @throws ApiError
     */
    public static updateSessionActivity(
        id: TSessionActivityId,
        requestBody: TUpdateSessionActivityRequest,
    ): CancelablePromise<TUpdateSessionActivityResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/session-activities/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete SessionActivity
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSessionActivity(
        id: TSessionActivityId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/session-activities/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List SessionActivity s
     * @param sessionId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListSessionActivitiesResponse TListSessionActivitiesResponse
     * @throws ApiError
     */
    public static listSessionActivities(
        sessionId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListSessionActivitiesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-activities',
            query: {
                'sessionId': sessionId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create SessionActivity
     * @param requestBody
     * @returns TCreateSessionActivityResponse TCreateSessionActivityResponse
     * @throws ApiError
     */
    public static createSessionActivity(
        requestBody: TCreateSessionActivityRequest,
    ): CancelablePromise<TCreateSessionActivityResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session-activities',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
