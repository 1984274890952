import { Box, CheckBox, Text } from 'grommet';
import { Alert, Cloud, Run } from 'grommet-icons';
import { PerformerType, Role, RoleActivity, RoleType, SessionActivity } from '/lib/api';
import { useEffect, useState } from 'react';
import { TalonTip } from '..';
import { useProjectStore, useSessionActivityStore } from '/src/context';

export interface IRoleActivityRowProps {
  activity: SessionActivity;
  role: Role;
}
export const RoleActivityRow = ({ role, activity }: IRoleActivityRowProps) => {
  //   const [stunts, setStunts] = useState<boolean>(false);
  //   const [hazardous, setHazardous] = useState<boolean>(false);
  //   const [wet, setWet] = useState<boolean>(false);
  const [roleActivity, setRoleActivity] = useState<RoleActivity | undefined>(undefined);
  const sessionActivityStore = useSessionActivityStore();
  const projectStore = useProjectStore();

  useEffect(() => {
    if (activity.role_activities) {
      const ra = activity.role_activities.find((ra) => ra.role?.id === role.id);
      ra && setRoleActivity(ra);
    }
  }, [activity]);

  const upsertRoleActivity = async (update: Partial<RoleActivity>) => {
    let ra;
    if (roleActivity) {
      ra = await sessionActivityStore.updateRoleActivity({ ...roleActivity, ...update });
    } else {
      ra = await sessionActivityStore.createRoleActivity({
        ...update,
        session_activity: activity,
        role: role,
        project: projectStore.currentProject,
      });
    }
    ra && setRoleActivity(ra);
  };

  return (
    <Box direction="row" gap="small" align="center" pad="xsmall">
      <Text size="small">{role.name}</Text>
      {role.performer_type === PerformerType.ON_CAMERA_PRINCIPAL && (
        <CheckBox
          name="own-stunts"
          checked={roleActivity ? roleActivity.is_own_stunt_performer : false}
          onChange={(event) => upsertRoleActivity({ is_own_stunt_performer: event.target.checked })}
        >
          {({ checked }: { checked: boolean }) => (
            <TalonTip content={<Text>Will this OCP do his/her own stunts?</Text>}>
              <Run color={checked ? 'accent-3' : 'light-3'} />
            </TalonTip>
          )}
        </CheckBox>
      )}
      {role.type === RoleType.SPECIALTY_DANCER && (
        <CheckBox
          name="hazardous"
          checked={roleActivity ? roleActivity.is_hazardous_work : false}
          onChange={(event) => upsertRoleActivity({ is_hazardous_work: event.target.checked })}
        >
          {({ checked }: { checked: boolean }) => (
            <TalonTip content={<Text>Will this dancer work in hazardous conditions?</Text>}>
              <Alert color={checked ? 'accent-3' : 'light-3'} />
            </TalonTip>
          )}
        </CheckBox>
      )}
      {role.performer_type === PerformerType.EXTRA && (
        <CheckBox
          name="wet"
          checked={roleActivity ? roleActivity.is_wet_snow_smoke_dust_work : false}
          onChange={(event) => upsertRoleActivity({ is_wet_snow_smoke_dust_work: event.target.checked })}
        >
          {({ checked }: { checked: boolean }) => (
            <TalonTip content={<Text>Will this extra work in wet, snowy, smokey, or dusty conditions?</Text>}>
              <Cloud color={checked ? 'accent-3' : 'light-3'} />
            </TalonTip>
          )}
        </CheckBox>
      )}
    </Box>
  );
};
