import { Box, Card, CardBody, CardHeader, Text } from 'grommet';
import { Close, Link } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { EditableText } from '..';
import { RolePanel, RoleGroupPanel, CostumePanel, PropPanel } from '.';
import { Document, DocumentType, Role, Script } from '/lib/api';
import { useEffect, useState } from 'react';
import { useProjectStore } from '../../../context';
import { toast } from 'react-toastify';

export interface ICurrentDocumentProps {
  doc: Document;
  update: (update: Document) => Promise<void>;
  close: () => void;
}
export const CurrentDocument = observer(({ doc, update, close }: ICurrentDocumentProps) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [currentScript, setCurrentScript] = useState<Script | undefined>(undefined);
  const projectStore = useProjectStore();

  useEffect(() => {
    // fetch script
    const getScript = async () => {
      if (!doc || doc.type !== DocumentType.SCRIPT) {
        setCurrentScript(undefined);
        return;
      }
      const script = await projectStore.getScriptByDocId(doc.id);
      script && setCurrentScript(script);
    };
    getScript();
  }, [doc]);
  const getSignedDownloadLink = async (id: string) => {
    if (!id) return;
    const link = await projectStore.getSignedDownloadLink(id);
    if (link) {
      window.open(link, '_blank');
    } else {
      toast.error('Error getting download link');
    }
  };
  return (
    <Card pad="medium" background="light-2" margin="medium" round="medium">
      <CardHeader>
        <Box width="100%" direction="row" justify="end">
          <Close onClick={() => close()} style={{ cursor: 'pointer' }} />
        </Box>
      </CardHeader>
      <CardBody>
        <Box direction="column">
          <Box direction="row">
            <Text margin={{ right: 'small' }}>{doc.type}:</Text>
            <EditableText val={doc.name} onChange={(name) => update({ ...doc, name })} />
            <Link
              onClick={() => getSignedDownloadLink(doc.id)}
              size="medium"
              color="brand"
              style={{ cursor: 'pointer', marginLeft: '10px', marginRight: 'auto' }}
            />
          </Box>
          {currentScript && (
            <Box direction="row">
              <RolePanel scriptId={currentScript.id} roles={roles} setRoles={setRoles} />
              <RoleGroupPanel scriptId={currentScript.id} />
              <CostumePanel scriptId={currentScript.id} roles={roles} />
              <PropPanel scriptId={currentScript.id} roles={roles} />
            </Box>
          )}
        </Box>
      </CardBody>
    </Card>
  );
});
