import { Box, Button, ButtonExtendedProps } from 'grommet';
import { Add, AddCircle } from 'grommet-icons';

export interface IAddNewButtonProps extends ButtonExtendedProps {
  onClick: (e?: React.MouseEvent) => void;
  label?: string;
  pad?: string;
  margin?: string;
  icon?: JSX.Element;
  tipText?: string;
}

export const AddNewButton = ({ onClick, label, icon, tipText, ...rest }: IAddNewButtonProps) => {
  const AddIcon = icon ? (
    <Box direction="row">
      {icon}
      <Add size="small" />
    </Box>
  ) : (
    <AddCircle />
  );

  return (
    <Button
      hoverIndicator
      onClick={() => onClick()}
      label={label}
      {...rest}
      icon={AddIcon}
      tip={
        tipText
          ? {
            content: tipText,
            dropProps: {
              background: 'light-2',
              round: 'small',
              pad: 'small',
              margin: 'small',
              border: 'all',
            },
            plain: true,
          }
          : undefined
      }
    />
  );
};
