/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateFeeRequest } from '../models/TCreateFeeRequest';
import type { TCreateFeeResponse } from '../models/TCreateFeeResponse';
import type { TFeeId } from '../models/TFeeId';
import type { TGetFeeResponse } from '../models/TGetFeeResponse';
import type { TListFeesResponse } from '../models/TListFeesResponse';
import type { TUpdateFeeRequest } from '../models/TUpdateFeeRequest';
import type { TUpdateFeeResponse } from '../models/TUpdateFeeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeeService {

    /**
     * Get Fee
     * @param id
     * @param relations
     * @returns TGetFeeResponse TGetFeeResponse
     * @throws ApiError
     */
    public static getFee(
        id: TFeeId,
        relations?: string,
    ): CancelablePromise<TGetFeeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/fees/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Fee
     * @param id
     * @param requestBody
     * @returns TUpdateFeeResponse TUpdateFeeResponse
     * @throws ApiError
     */
    public static updateFee(
        id: TFeeId,
        requestBody: TUpdateFeeRequest,
    ): CancelablePromise<TUpdateFeeResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/fees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Fee
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteFee(
        id: TFeeId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/fees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Fees
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListFeesResponse TListFeesResponse
     * @throws ApiError
     */
    public static listFees(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListFeesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/fees',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Fee
     * @param requestBody
     * @returns TCreateFeeResponse TCreateFeeResponse
     * @throws ApiError
     */
    public static createFee(
        requestBody: TCreateFeeRequest,
    ): CancelablePromise<TCreateFeeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/fees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
