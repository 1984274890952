import { Multimedia } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SessionRow } from '.';
import { TalonCard } from '../..';
import { Location, Session, Shoot } from '/lib/api';
import { useEstimateStore, useProjectStore, useSessionStore } from '/src/context';

export interface ISessionsPanelProps {
  shoot: Shoot;
}

export const SessionsPanel = observer(({ shoot }: ISessionsPanelProps) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();
  const sessionStore = useSessionStore();

  useEffect(() => {
    const getSessions = async () => {
      if (!shoot) {
        setSessions([]);
        return;
      }
      const s = await sessionStore.listSessions(shoot.id);
      // sort activities within each session by start_date
      s?.forEach((session) => {
        session.activities?.sort((a, b) => {
          if (a.start_time < b.start_time) {
            return -1;
          }
          if (a.start_time > b.start_time) {
            return 1;
          }
          return 0;
        });
      });
      setSessions(s || []);
    };
    getSessions();
  }, [shoot]);

  useEffect(() => {
    const getLocations = async () => {
      if (!projectStore.currentProject) return;
      const l = await projectStore.getLocationsByProjectId(projectStore.currentProject.id);
      setLocations(l || []);
    };
    getLocations();
  }, []);

  const chooseSession = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const selectedNames = () => {
    const dates = selected.map((s) => sessions.find((item) => item.id === s)?.date);
    const formattedDates = dates.map((d) => new Date(d || '').toLocaleDateString());
    return formattedDates.join(', ');
  };

  const createSession = async () => {
    const sortedSessions = sessions.toSorted((a, b) => {
      const aDate = new Date(`${a.date}T00:00:00`);
      const bDate = new Date(`${b.date}T00:00:00`);
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return 0;
    });
    // if there are already sessions, use the last date as the default
    const lastDate = sortedSessions.length
      ? new Date(`${sortedSessions[sortedSessions.length - 1].date}T00:00:00`)
      : new Date();
    if (shoot) {
      const session = await sessionStore.createSession(lastDate.toISOString(), shoot.id);
      session && setSessions([...sessions, session]);
    }
  };

  const deleteSessions = async () => {
    // delete the selected sessions
    await Promise.all(selected.map((s) => sessionStore.deleteSession(s)));
    // remove the deleted sessions from the list
    setSessions(sessions.filter((session) => !selected.includes(session.id)));
    // refresh estimate totals
    await estimateStore.refreshEstimateTotals(estimateStore.currentEstimate?.id);
    setSelected([]);
  };

  return (
    <TalonCard
      label="Session"
      labelPlural={`${shoot.name} Sessions`}
      newItem={() => createSession()}
      deleteItems={() => deleteSessions()}
      selectedNames={selectedNames()}
      addIcon={<Multimedia />}
    >
      {sessions.map((session) => (
        <SessionRow
          sessionId={session.id}
          locations={locations}
          onChecked={() => chooseSession(session.id)}
          checked={selected.includes(session.id)}
          key={session.id}
        />
      ))}
    </TalonCard>
  );
});
