export const getAddressObject = (address_components: Array<google.maps.GeocoderAddressComponent>) => {
  interface IShouldBeComponent {
    [key: string]: Array<string>;
  }
  const ShouldBeComponent: IShouldBeComponent = {
    number: ['street_number'],
    zip: ['postal_code'],
    street: ['street_address', 'route'],
    aptOrUnit: ['subpremise'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  interface IAddress {
    [key: string]: string | number;
  }
  const address: IAddress = {
    aptOrUnit: '',
    number: '',
    zip: '',
    street: '',
    state: '',
    city: '',
    country: '',
  };
  address_components.forEach((component) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  address.streetAddress = `${address.number} ${address.street}`;
  return address;
};
