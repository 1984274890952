import { Box, Card, CheckBox, ColumnConfig, DataTable, Heading, Tab, Tabs, Text } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { EditableText, TalonTip } from '..';
import { USD } from '../../../utils/currency';
import { Estimate, EstimateStatus, SessionEstimateItem } from '/lib/api';
import { useEstimateStore, useProjectStore } from '/src/context';

export interface ICurrentEstimateProps {
  gridArea?: string;
}
export const CurrentEstimate = observer(({ gridArea }: ICurrentEstimateProps) => {
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();

  useEffect(() => {
    if (estimateStore.currentEstimate) {
      console.log('Current Estimate:', toJS(estimateStore.currentEstimate));
    }
  }, [projectStore.currentProject, estimateStore.currentEstimate]);

  const updateEstimate = async (updatedEstimate: Estimate) => {
    await estimateStore.updateEstimate(updatedEstimate);
    const estimate = await estimateStore.getEstimate(updatedEstimate.id, 'shoot,session_estimates');
    if (estimate) {
      estimateStore.setCurrentEstimate(estimate);
    }
  };

  const columns: ColumnConfig<SessionEstimateItem>[] = [
    {
      property: 'title',
      header: 'Title',
      primary: true,
    },
    {
      property: 'units',
      header: 'Units',
      render: (datum: SessionEstimateItem) => `${datum.units} ${datum.unit_type}`,
      align: 'end',
    },
    {
      property: 'rate',
      header: 'Rate',
      render: (datum: SessionEstimateItem) => USD(datum.rate),
      align: 'end',
    },
    {
      property: 'subtotal',
      header: 'Subtotal',
      render: (datum: SessionEstimateItem) => USD(datum.subtotal),
      align: 'end',
      aggregate: 'sum',
    },
    {
      property: 'subtotal_plus_fringe',
      header: 'Subtotal + Fringe',
      render: (datum: SessionEstimateItem) => USD(datum.subtotal_plus_fringe),
      align: 'end',
      aggregate: 'sum',
    },
  ];

  return (
    <Box
      round="medium"
      background="white"
      border={{ color: '#d9d9e3' }}
      justify="start"
      alignContent="start"
      gridArea={gridArea}
      direction="column"
      flex="grow"
    >
      <Card pad="medium" background="light-2" margin="medium" round="medium" overflow="auto">
        <Box direction="column">
          {/* Estimate Title */}
          <Heading level="3" alignSelf="center">
            <EditableText
              val={estimateStore.currentEstimate?.title}
              onChange={(t) =>
                estimateStore.currentEstimate?.id && updateEstimate({ ...estimateStore.currentEstimate, title: t })
              }
              textSize="xlarge"
            />
          </Heading>
          <Box direction="row" justify="between">
            <Box direction="row" gap="small" pad="small" align="center">
              <Box direction="row" gap="small" align="center">
                <Text>Shoot: {estimateStore.currentEstimate?.shoot?.name}</Text>
              </Box>
            </Box>
            <Box direction="row" gap="large" align="center">
              <Text>Total Cost: {USD(estimateStore.currentEstimate?.total)}</Text>
              <Text>Total Plus Fringe: {USD(estimateStore.currentEstimate?.total_plus_fringe)}</Text>
            </Box>
            <Box direction="row" align="start">
              <Heading level="5" fill={false}>
                Estimate Status&nbsp;
                <TalonTip
                  content={
                    <Text>
                      Draft estimates are &quot;ballpark&quot; estimates. Final estimates have been approved by the
                      client.
                    </Text>
                  }
                >
                  <CircleInformation size="small" />
                </TalonTip>
              </Heading>
              <CheckBox
                pad="medium"
                label={estimateStore.currentEstimate?.status}
                checked={estimateStore.currentEstimate?.status === EstimateStatus.FINAL}
                onChange={(event) =>
                  estimateStore.currentEstimate?.id &&
                  updateEstimate({
                    ...estimateStore.currentEstimate,
                    status: event.target.checked ? EstimateStatus.FINAL : EstimateStatus.DRAFT,
                  })
                }
                toggle
              />
            </Box>
          </Box>
        </Box>
      </Card>

      {estimateStore.currentEstimate?.shoot && (
        <Tabs>
          <Tab title="Sessions">
            <Box direction="column" gap="small" pad="small" align="center">
              {estimateStore.currentEstimate?.session_estimates?.map((sessionEstimate) => (
                <Box direction="column" gap="small" pad="small" key={sessionEstimate.id}>
                  <Box direction="row" gap="small" pad="small">
                    <Text>{sessionEstimate.title}</Text>
                    <Text>{USD(sessionEstimate.subtotal)}</Text>
                  </Box>
                  <Box direction="column" gap="small" pad="small">
                    <DataTable columns={columns} data={sessionEstimate.items} />
                    {/* {sessionEstimate.items?.map((item) => (
                      <Box key={item.id} direction="row" pad={{ horizontal: 'medium' }} gap="small">
                        <Text>{item.title}:</Text>
                        <Text>{USD(item.subtotal)}</Text>
                      </Box>
                    ))} */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Tab>
          <Tab title="Usage">
            <Box direction="column" align="center">
              <Heading level="3" margin="medium">
                Usage
              </Heading>
            </Box>
          </Tab>
        </Tabs>
      )}
    </Box>
  );
});
