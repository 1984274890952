const getEnvironmentVariable = (unvalidatedEnvironmentVariable: string | undefined): string => {
  if (!unvalidatedEnvironmentVariable) {
    throw new Error(`Couldn't find environment variable: ${unvalidatedEnvironmentVariable}`);
  } else {
    return unvalidatedEnvironmentVariable;
  }
};

export const env = {
  apiUrl: getEnvironmentVariable(process.env.NEXT_PUBLIC_API_URL),
  apiBase: getEnvironmentVariable(process.env.NEXT_PUBLIC_API_BASE),
  apiVersion: getEnvironmentVariable(process.env.NEXT_PUBLIC_API_VERSION),
  posthog: {
    options: {
      api_host: getEnvironmentVariable(process.env.REACT_APP_PUBLIC_POSTHOG_HOST),
    },
    api_key: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  },
};
