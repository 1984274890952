import { Box, Card, CardHeader, CardBody, Text, TextInput, CardFooter, Button } from 'grommet';
import * as Icons from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { Project } from '/lib/api';
import { Delete, CreateSelect } from '/src/components/ui';
import { useState, useEffect } from 'react';
import { useProjectStore, useUserStore } from '/src/context';
import { toast } from 'react-toastify';

export const ProjectCard = observer(({ project, onClick }: { project?: Project; onClick?: () => void }) => {
  const [client, setClient] = useState<string>();
  const [name, setName] = useState<string>('');
  const [product, setProduct] = useState<string | undefined>('');
  const [code, setCode] = useState<string>('');
  const projectStore = useProjectStore();
  const userStore = useUserStore();

  useEffect(() => {
    if (!project) return;
    setClient(project.client);
    setName(project.name);
    setProduct(project.product);
    setCode(project.job_code);
  }, [project]);

  const createProject = async () => {
    if (!name || !client || !product) {
      toast.error('Please fill out all fields');
      return;
    }
    if (!userStore.user.active_group) {
      toast.error('An internal error has occurred. Please refresh the page and try again.');
      return;
    }
    await projectStore.createProject({
      name,
      groupId: userStore.user.active_group?.id,
      client,
      product,
      jobCode: code,
    });
    setClient('');
    setName('');
    setProduct('');
    setCode('');
  };

  const deleteProject = async () => {
    if (!project) {
      toast.error('An internal error has occurred. Please refresh the page and try again.');
      return;
    }
    projectStore.deleteProject(project.id);
  };

  // const updateProject = async (updatedProject: Project) => {
  //   if (!project) {
  //     toast.error('An internal error has occurred. Please refresh the page and try again.');
  //     return;
  //   }
  //   await projectStore.updateProject(project.id, updatedProject);
  // };

  return (
    <Card background={project ? 'light-1' : 'light-2'} margin="medium" height="medium">
      <CardHeader pad="medium" onClick={project && onClick ? () => onClick() : undefined}>
        {project ? (
          `${project.name} (${project.job_code})`
        ) : (
          <TextInput
            placeholder="New project name..."
            id="project-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </CardHeader>
      <CardBody pad="medium">
        <Box direction="column">
          <Box margin="xsmall">
            {project ? (
              <Text size="xsmall" margin="small">
                Advertiser: {project.client}
              </Text>
            ) : (
              <CreateSelect
                id="select-client"
                placeholder="Advertiser"
                defaultOptions={projectStore.clientList}
                onChange={setClient}
                defaultValue={client}
              />
            )}
          </Box>
          <Box margin="xsmall">
            {project ? (
              <Text size="xsmall" margin="small">
                Product: {project.product}
              </Text>
            ) : (
              <TextInput
                placeholder="Product"
                id="product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              />
            )}
          </Box>
          <Box margin="xsmall">
            {!project && (
              <TextInput placeholder="Job Code" id="job-code" value={code} onChange={(e) => setCode(e.target.value)} />
            )}
          </Box>
        </Box>
      </CardBody>
      <CardFooter pad={{ horizontal: 'small' }} background="light-2" justify="end">
        {project ? (
          <Box direction="row" justify="between" gap="small">
            <Box direction="row" margin="small">
              {project.estimates?.length > 0 && <Button icon={<Icons.Table color="plain" />} hoverIndicator />}
            </Box>
            <Box direction="row" margin="small">
              <Button icon={<Icons.Favorite color="red" />} hoverIndicator />
              <Button icon={<Icons.ShareOption color="plain" />} hoverIndicator />
              <Delete onClick={() => deleteProject()} name={name} hoverIndicator />
            </Box>
          </Box>
        ) : (
          <Button hoverIndicator icon={<Icons.Add color="plain" type="submit" onClick={() => createProject()} />} />
        )}
      </CardFooter>
    </Card>
  );
});
