/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TSecretKeyRequest } from '../models/TSecretKeyRequest';
import type { TSecretKeyResponse } from '../models/TSecretKeyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecretService {

    /**
     * Secret Key
     * @param requestBody
     * @returns TSecretKeyResponse TSecretKeyResponse
     * @throws ApiError
     */
    public static getSecretKey(
        requestBody: TSecretKeyRequest,
    ): CancelablePromise<TSecretKeyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/secrets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
