import { FormTrash } from 'grommet-icons';
import { Box, Button, ButtonExtendedProps, Layer, Spinner, Text } from 'grommet';
import { useState, useEffect } from 'react';

export type IDeleteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (e?: React.SyntheticEvent) => Promise<any>;
  name: string;
} & ButtonExtendedProps;

export const Delete = ({ onClick, name, ...rest }: IDeleteProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    const doDelete = async () => {
      await onClick();
      setDeleting(false);
      setOpen(false);
    };
    if (open && deleting) doDelete();
  }, [deleting]);

  const onCancel = () => setOpen(false);

  const acceptFocus = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Button
        hoverIndicator
        onClick={(e) => acceptFocus(e)}
        style={{ cursor: 'pointer' }}
        {...rest}
        icon={<FormTrash color="red" />}
      />

      {open && (
        <Layer onClickOutside={onCancel} onEsc={onCancel}>
          {deleting ? (
            <Box align="center" justify="center" pad="medium">
              <Spinner size="large" />
            </Box>
          ) : (
            <Box pad="medium" gap="medium">
              <Text>Are you sure you want to delete {name}?</Text>
              <Box direction="row" align="center" justify="end" gap="small">
                <Button label="Cancel" onClick={onCancel} />
                <Button label="Delete" onClick={() => setDeleting(true)} primary />
              </Box>
            </Box>
          )}
        </Layer>
      )}
    </>
  );
};
