/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateFeatureRequest } from '../models/TCreateFeatureRequest';
import type { TCreateFeatureResponse } from '../models/TCreateFeatureResponse';
import type { TFeatureId } from '../models/TFeatureId';
import type { TGetFeatureResponse } from '../models/TGetFeatureResponse';
import type { TListFeaturesResponse } from '../models/TListFeaturesResponse';
import type { TUpdateFeatureRequest } from '../models/TUpdateFeatureRequest';
import type { TUpdateFeatureResponse } from '../models/TUpdateFeatureResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeatureService {

    /**
     * Get Feature
     * @param id
     * @param relations
     * @returns TGetFeatureResponse TGetFeatureResponse
     * @throws ApiError
     */
    public static getFeature(
        id: TFeatureId,
        relations?: string,
    ): CancelablePromise<TGetFeatureResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/features/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Feature
     * @param id
     * @param requestBody
     * @returns TUpdateFeatureResponse TUpdateFeatureResponse
     * @throws ApiError
     */
    public static updateFeature(
        id: TFeatureId,
        requestBody: TUpdateFeatureRequest,
    ): CancelablePromise<TUpdateFeatureResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/features/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Feature
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteFeature(
        id: TFeatureId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/features/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Features
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListFeaturesResponse TListFeaturesResponse
     * @throws ApiError
     */
    public static listFeatures(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListFeaturesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/features',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Feature
     * @param requestBody
     * @returns TCreateFeatureResponse TCreateFeatureResponse
     * @throws ApiError
     */
    public static createFeature(
        requestBody: TCreateFeatureRequest,
    ): CancelablePromise<TCreateFeatureResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/features',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
