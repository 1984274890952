import { PerformerType, RoleType } from '../../lib/api';

export const filterRoleTypes = (performer_type: string) => {
  switch (performer_type) {
    case PerformerType.AMERICAN_FEDERATION_OF_MUSICIANS:
      return [
        RoleType.LEADER_ARRANGER_COMPOSER,
        RoleType.COPYIST_SIDE_MUSICIAN,
        RoleType.COPYIST_SIDE_MUSICIAN_1_DOUBLE,
        RoleType.COPYIST_SIDE_MUSICIAN_2_DOUBLES,
        RoleType.COPYIST_SIDE_MUSICIAN_3_DOUBLES,
        RoleType.COPYIST_SIDE_MUSICIAN_4_DOUBLES,
      ];

    case PerformerType.ON_CAMERA_PRINCIPAL:
      return [
        RoleType.ACTOR,
        RoleType.SINGER,
        RoleType.SPECIALTY_DANCER,
        RoleType.SPECIALTY_ACT,
        RoleType.STUNT_PERFORMER,
        RoleType.PILOT,
        RoleType.CLOWN,
      ];

    case PerformerType.OFF_CAMERA_PRINCIPAL:
      return [RoleType.NARRATOR, RoleType.ANNOUNCER, RoleType.PUPPETEER, RoleType.STUNT_COORDINATOR];

    default:
      return Object.values(RoleType);
  }
};
