import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Costume, CostumeService, TCreateCostumeRequest, TUpdateCostumeRequest } from '/lib/api';

export class CostumeStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  getCostumesByScriptId = async (scriptId: string) => {
    try {
      const { costumes } = await CostumeService.listCostumes(scriptId, undefined, undefined, undefined, 'owner');
      return costumes;
    } catch (e) {
      this.handleError(e);
    }
  };

  createCostume = async ({ name, type, scriptIds, projectId }: TCreateCostumeRequest) => {
    try {
      const { costume } = await CostumeService.createCostume({ name, type, scriptIds, projectId });
      return costume;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateCostume = async (costume: Costume) => {
    const updateRequest: TUpdateCostumeRequest = {
      ...costume,
      ownerId: costume.owner?.id,
      scriptIds: costume.scripts?.map((s) => s.id),
      sessionActivityIds: costume.session_activities?.map((sa) => sa.id),
    };
    try {
      const { costume: updatedCostume } = await CostumeService.updateCostume(costume.id, updateRequest);
      return updatedCostume;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteCostume = async (costumeId: string) => {
    try {
      await CostumeService.deleteCostume(costumeId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
