/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateProjectRequest } from '../models/TCreateProjectRequest';
import type { TCreateProjectResponse } from '../models/TCreateProjectResponse';
import type { TGetProjectResponse } from '../models/TGetProjectResponse';
import type { TListProjectsResponse } from '../models/TListProjectsResponse';
import type { TProjectId } from '../models/TProjectId';
import type { TUpdateProjectRequest } from '../models/TUpdateProjectRequest';
import type { TUpdateProjectResponse } from '../models/TUpdateProjectResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * Get Project
     * @param id
     * @param relations
     * @returns TGetProjectResponse TGetProjectResponse
     * @throws ApiError
     */
    public static getProject(
        id: TProjectId,
        relations?: string,
    ): CancelablePromise<TGetProjectResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Project
     * @param id
     * @param requestBody
     * @returns TUpdateProjectResponse TUpdateProjectResponse
     * @throws ApiError
     */
    public static updateProject(
        id: TProjectId,
        requestBody: TUpdateProjectRequest,
    ): CancelablePromise<TUpdateProjectResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Project
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteProject(
        id: TProjectId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Projects
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListProjectsResponse TListProjectsResponse
     * @throws ApiError
     */
    public static listProjects(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListProjectsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Project
     * @param requestBody
     * @returns TCreateProjectResponse TCreateProjectResponse
     * @throws ApiError
     */
    public static createProject(
        requestBody: TCreateProjectRequest,
    ): CancelablePromise<TCreateProjectResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
