import { Box, Card, CardHeader, CardBody, CardFooter } from 'grommet';
import { AddNewButton, Delete } from '..';

export interface ITalonCardProps extends React.PropsWithChildren {
  newItem?: () => void;
  deleteItems: () => Promise<void>;
  label: string;
  labelPlural?: string;
  addIcon?: JSX.Element;
  buttons?: React.ReactNode[];
  selectedNames?: string;
}

export const TalonCard = ({
  newItem,
  deleteItems,
  label,
  labelPlural,
  addIcon,
  buttons,
  children,
  selectedNames,
}: ITalonCardProps) => {
  return (
    <Card margin="small">
      <CardHeader pad="small" background="dark-1">
        {labelPlural || label}
      </CardHeader>
      <CardBody>{children}</CardBody>
      <CardFooter background="light-2" justify="end">
        <Box direction="row">
          {buttons}
          {newItem && <AddNewButton onClick={newItem} tipText={`New ${label}`} icon={addIcon} />}
          <Delete onClick={() => deleteItems()} name={selectedNames || 'these items'} disabled={!selectedNames} />
        </Box>
      </CardFooter>
    </Card>
  );
};
