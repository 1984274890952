/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TAccessToken } from '../models/TAccessToken';
import type { TChangeUserActiveGroupRequest } from '../models/TChangeUserActiveGroupRequest';
import type { TChangeUserActiveGroupResponse } from '../models/TChangeUserActiveGroupResponse';
import type { TChangeUserPasswordRequest } from '../models/TChangeUserPasswordRequest';
import type { TConfirmEmailRequest } from '../models/TConfirmEmailRequest';
import type { TCreateUserRequest } from '../models/TCreateUserRequest';
import type { TCreateUserResponse } from '../models/TCreateUserResponse';
import type { TForgotPasswordRequest } from '../models/TForgotPasswordRequest';
import type { TGetUserResponse } from '../models/TGetUserResponse';
import type { TListUserAllowedFeaturesResponse } from '../models/TListUserAllowedFeaturesResponse';
import type { TListUsersResponse } from '../models/TListUsersResponse';
import type { TResetUserPasswordRequest } from '../models/TResetUserPasswordRequest';
import type { TSignUpRequest } from '../models/TSignUpRequest';
import type { TUpdateUserRequest } from '../models/TUpdateUserRequest';
import type { TUpdateUserResponse } from '../models/TUpdateUserResponse';
import type { TUserId } from '../models/TUserId';
import type { TValidatePasswordResetTokenResponse } from '../models/TValidatePasswordResetTokenResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get Current User
     * @param relations
     * @returns TGetUserResponse Ok
     * @throws ApiError
     */
    public static currentUser(
        relations?: string,
    ): CancelablePromise<TGetUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/current',
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Get User
     * @param id
     * @param relations
     * @returns TGetUserResponse TGetUserResponse
     * @throws ApiError
     */
    public static getUser(
        id: TUserId,
        relations?: string,
    ): CancelablePromise<TGetUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update User
     * @param id
     * @param requestBody
     * @returns TUpdateUserResponse TUpdateUserResponse
     * @throws ApiError
     */
    public static updateUser(
        id: TUserId,
        requestBody: TUpdateUserRequest,
    ): CancelablePromise<TUpdateUserResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete User
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteUser(
        id: TUserId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Allowed Features
     * @param id
     * @returns TListUserAllowedFeaturesResponse TGetUserResponse
     * @throws ApiError
     */
    public static getAllowedFeatures(
        id: TUserId,
    ): CancelablePromise<TListUserAllowedFeaturesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}/features',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Users
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListUsersResponse TListUsersResponse
     * @throws ApiError
     */
    public static listUsers(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListUsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create User
     * @param requestBody
     * @returns TCreateUserResponse TCreateUserResponse
     * @throws ApiError
     */
    public static createUser(
        requestBody: TCreateUserRequest,
    ): CancelablePromise<TCreateUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a Group and a User
     * @param requestBody
     * @returns boolean TSignUpResponse
     * @throws ApiError
     */
    public static signUp(
        requestBody: TSignUpRequest,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update User
     * @param id
     * @param requestBody
     * @returns TChangeUserActiveGroupResponse TUpdateUserResponse
     * @throws ApiError
     */
    public static changeActiveGroup(
        id: TUserId,
        requestBody: TChangeUserActiveGroupRequest,
    ): CancelablePromise<TChangeUserActiveGroupResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}/active-group',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change Password
     * @param id TUserId
     * @param requestBody TChangeUserPasswordRequest
     * @returns void
     * @throws ApiError
     */
    public static changePassword(
        id: TUserId,
        requestBody: TChangeUserPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}/password',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Confirm Email
     * @param requestBody
     * @returns TAccessToken Ok
     * @throws ApiError
     */
    public static confirmEmail(
        requestBody: TConfirmEmailRequest,
    ): CancelablePromise<TAccessToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/confirm-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Resend Confirmation Email
     * @returns void
     * @throws ApiError
     */
    public static resendConfirmationEmail(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/resend-confirmation-email',
        });
    }

    /**
     * Forgot Password
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static forgotPassword(
        requestBody: TForgotPasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reset Password
     * @param requestBody
     * @returns TAccessToken Ok
     * @throws ApiError
     */
    public static resetPassword(
        requestBody: TResetUserPasswordRequest,
    ): CancelablePromise<TAccessToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Validate Password Reset Token
     * @param token
     * @returns TValidatePasswordResetTokenResponse Ok
     * @throws ApiError
     */
    public static validatePasswordResetToken(
        token: string,
    ): CancelablePromise<TValidatePasswordResetTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/validate-password-reset/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * Get Email Availability
     * @param params
     * @returns boolean Ok
     * @throws ApiError
     */
    public static isEmailAvailable(
        params: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/email/availability/{params}',
            path: {
                'params': params,
            },
        });
    }

}
