import { User } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Panel } from '..';
import { useRoleStore } from '../../../context';
import { PerformerType, Role, RoleType } from '/lib/api';
import { filterRoleTypes } from '/src/utils/listFilters';

export interface IRolePanelProps {
  scriptId?: string;
  roles: Role[];
  setRoles: (roles: Role[]) => void;
}

export const RolePanel = observer(({ scriptId, roles, setRoles }: IRolePanelProps) => {
  const roleStore = useRoleStore();

  useEffect(() => {
    if (!scriptId) return;
    const getRoles = async () => {
      const roles = await roleStore.getRolesByScriptId(scriptId);
      roles && setRoles(roles.filter((r) => !r.role_group));
    };
    getRoles();
  }, [scriptId]);

  const newRole = async () => {
    if (!scriptId) return;
    const roleRequest = {
      name: 'New Role',
      type: RoleType.ACTOR,
      performerType: PerformerType.ON_CAMERA_PRINCIPAL,
      scriptId: scriptId,
    };
    const role = await roleStore.createRole(roleRequest);
    roles && role && setRoles([...roles, role]);
  };

  const deleteRoles = async (ids: string[]) => {
    ids.forEach(async (id) => await roleStore.deleteRole(id));
    setRoles(roles.filter((r) => !ids.includes(r.id)));
  };

  const updateRole = async (update: Role, index: number) => {
    const role = await roleStore.updateRole(update);
    if (!role) return;
    const newRoles = [...roles];
    newRoles[index] = role;
    setRoles(newRoles);
  };

  return (
    <Panel
      items={roles}
      newItem={newRole}
      deleteItems={deleteRoles}
      updateItem={updateRole}
      label="Role"
      labelPlural="Roles"
      addIcon={<User />}
      types={[
        {
          label: 'Performer Type',
          key: 'performer_type',
          values: Object.values(PerformerType).filter((v) => !v.includes('Group')),
        },
        {
          label: 'Role Type',
          key: 'type',
          values: Object.values(RoleType),
          filterKey: 'performer_type',
          filterFunction: filterRoleTypes,
        },
      ]}
    />
  );
});
