/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCommercialId } from '../models/TCommercialId';
import type { TCreateCommercialRequest } from '../models/TCreateCommercialRequest';
import type { TCreateCommercialResponse } from '../models/TCreateCommercialResponse';
import type { TGetCommercialResponse } from '../models/TGetCommercialResponse';
import type { TListCommercialsResponse } from '../models/TListCommercialsResponse';
import type { TUpdateCommercialRequest } from '../models/TUpdateCommercialRequest';
import type { TUpdateCommercialResponse } from '../models/TUpdateCommercialResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommercialService {

    /**
     * Get Commercial
     * @param id
     * @param relations
     * @returns TGetCommercialResponse TGetCommercialResponse
     * @throws ApiError
     */
    public static getCommercial(
        id: TCommercialId,
        relations?: string,
    ): CancelablePromise<TGetCommercialResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commercials/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Commercial
     * @param id
     * @param requestBody
     * @returns TUpdateCommercialResponse TUpdateCommercialResponse
     * @throws ApiError
     */
    public static updateCommercial(
        id: TCommercialId,
        requestBody: TUpdateCommercialRequest,
    ): CancelablePromise<TUpdateCommercialResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/commercials/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Commercial
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteCommercial(
        id: TCommercialId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/commercials/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Commercials
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListCommercialsResponse TListCommercialsResponse
     * @throws ApiError
     */
    public static listCommercials(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListCommercialsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commercials',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Commercial
     * @param requestBody
     * @returns TCreateCommercialResponse TCreateCommercialResponse
     * @throws ApiError
     */
    public static createCommercial(
        requestBody: TCreateCommercialRequest,
    ): CancelablePromise<TCreateCommercialResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commercials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
