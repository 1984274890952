/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateEstimateRequest } from '../models/TCreateEstimateRequest';
import type { TCreateEstimateResponse } from '../models/TCreateEstimateResponse';
import type { TEstimateId } from '../models/TEstimateId';
import type { TGetEstimateResponse } from '../models/TGetEstimateResponse';
import type { TGetEstimateTotalsResponse } from '../models/TGetEstimateTotalsResponse';
import type { TGetSessionEstimateSubtotalsResponse } from '../models/TGetSessionEstimateSubtotalsResponse';
import type { TListEstimatesResponse } from '../models/TListEstimatesResponse';
import type { TSessionEstimateId } from '../models/TSessionEstimateId';
import type { TUpdateEstimateRequest } from '../models/TUpdateEstimateRequest';
import type { TUpdateEstimateResponse } from '../models/TUpdateEstimateResponse';
import type { TUpdateEstimateStatusRequest } from '../models/TUpdateEstimateStatusRequest';
import type { TUpdateEstimateStatusResponse } from '../models/TUpdateEstimateStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EstimateService {

    /**
     * Get Estimate
     * @param id
     * @param relations
     * @returns TGetEstimateResponse TGetEstimateResponse
     * @throws ApiError
     */
    public static getEstimate(
        id: TEstimateId,
        relations?: string,
    ): CancelablePromise<TGetEstimateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/estimates/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Estimate
     * @param id
     * @param requestBody
     * @returns TUpdateEstimateResponse TUpdateEstimateResponse
     * @throws ApiError
     */
    public static updateEstimate(
        id: TEstimateId,
        requestBody: TUpdateEstimateRequest,
    ): CancelablePromise<TUpdateEstimateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/estimates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Estimate
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteEstimate(
        id: TEstimateId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/estimates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get Estimate Totals
     * @param id
     * @returns TGetEstimateTotalsResponse TGetEstimateTotalsResponse
     * @throws ApiError
     */
    public static getEstimateTotals(
        id: TEstimateId,
    ): CancelablePromise<TGetEstimateTotalsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/estimates/{id}/totals',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Estimates
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListEstimatesResponse TListEstimatesResponse
     * @throws ApiError
     */
    public static listEstimates(
        projectId: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListEstimatesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/estimates',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Estimate
     * @param requestBody
     * @returns TCreateEstimateResponse TCreateEstimateResponse
     * @throws ApiError
     */
    public static createEstimate(
        requestBody: TCreateEstimateRequest,
    ): CancelablePromise<TCreateEstimateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/estimates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Estimate Status
     * @param id
     * @param requestBody
     * @returns TUpdateEstimateStatusResponse TUpdateEstimateResponse
     * @throws ApiError
     */
    public static updateEstimateStatus(
        id: TEstimateId,
        requestBody: TUpdateEstimateStatusRequest,
    ): CancelablePromise<TUpdateEstimateStatusResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/estimates/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Session Estimate Subtotals
     * @param id
     * @returns TGetSessionEstimateSubtotalsResponse TGetSessionEstimateSubtotalsResponse
     * @throws ApiError
     */
    public static getSessionEstimateSubtotals(
        id: TSessionEstimateId,
    ): CancelablePromise<TGetSessionEstimateSubtotalsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimates/{id}/subtotals',
            path: {
                'id': id,
            },
        });
    }

}
