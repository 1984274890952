/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Role Type
 */
export enum RoleType {
    ACTOR = 'Actor',
    SPEAKER = 'Speaker',
    NARRATOR = 'Narrator',
    ANNOUNCER = 'Announcer',
    SINGER = 'Singer',
    SPECIALTY_DANCER = 'Specialty Dancer',
    SPECIALTY_ACT = 'Specialty Act',
    PUPPETEER = 'Puppeteer',
    STUNT_PERFORMER = 'Stunt Performer',
    STUNT_COORDINATOR = 'Stunt Coordinator',
    PILOT = 'Pilot',
    CLOWN = 'Clown',
    STAND_IN = 'Stand In',
    HAND_MODEL = 'Hand Model',
    HAND_MODEL_DOUBLE_SCALE = 'Hand Model Double Scale',
    OTHER = 'Other',
    LEADER_ARRANGER_COMPOSER = 'Leader Arranger Composer',
    COPYIST_SIDE_MUSICIAN = 'Copyist Side Musician',
    COPYIST_SIDE_MUSICIAN_1_DOUBLE = 'Copyist Side Musician 1 Double',
    COPYIST_SIDE_MUSICIAN_2_DOUBLES = 'Copyist Side Musician 2 Doubles',
    COPYIST_SIDE_MUSICIAN_3_DOUBLES = 'Copyist Side Musician 3 Doubles',
    COPYIST_SIDE_MUSICIAN_4_DOUBLES = 'Copyist Side Musician 4 Doubles',
}
