import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Prop, PropService, TCreatePropRequest, TUpdatePropRequest } from '/lib/api';

export class PropStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  getPropsByScriptId = async (scriptId: string) => {
    try {
      const { props } = await PropService.listProps(scriptId);
      return props;
    } catch (e) {
      this.handleError(e);
    }
  };

  createProp = async ({ name, type, scriptIds, projectId }: TCreatePropRequest) => {
    try {
      const { prop } = await PropService.createProp({ name, type, scriptIds, projectId });
      return prop;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateProp = async (prop: Prop) => {
    const updateRequest: TUpdatePropRequest = {
      ...prop,
      ownerId: prop.owner?.id,
      scriptIds: prop.scripts?.map((s) => s.id),
      sessionActivityIds: prop.session_activities?.map((sa) => sa.id),
    };
    try {
      const { prop: updatedProp } = await PropService.updateProp(prop.id, updateRequest);
      return updatedProp;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteProp = async (propId: string) => {
    try {
      await PropService.deleteProp(propId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
