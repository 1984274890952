/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Usage Unit Type
 */
export enum UsageUnitType {
    USES = 'Uses',
    CITIES = 'Cities',
}
