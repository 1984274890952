import { Avatar, Box, ButtonExtendedProps, Menu, Text } from 'grommet';
import { User, Logout, Organization, UserAdmin } from 'grommet-icons';
import { useUserStore } from '/src/context';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { usePostHog } from 'posthog-js/react';

export const UserMenu = observer(() => {
  const userStore = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const doLogout = () => {
    userStore.logout();
    posthog.reset();
    navigate('/login');
  };

  const userMenu: ButtonExtendedProps[] = [
    {
      label: (
        <Box alignSelf="center">
          <Text>Profile</Text>
        </Box>
      ),
      onClick: () => navigate('/profile'),
      icon: (
        <Box pad="small">
          <User />
        </Box>
      ),
      justify: 'start',
      active: location.pathname === '/profile',
    },
    {
      label: <Box alignSelf="center">Account</Box>,
      onClick: () => navigate('/account'),
      icon: (
        <Box pad="small">
          <Organization />
        </Box>
      ),
      justify: 'start',
      active: location.pathname === '/account',
    },
    {
      label: <Box alignSelf="center">Logout</Box>,
      onClick: () => doLogout(),
      icon: (
        <Box pad="small">
          <Logout />
        </Box>
      ),
      color: 'accent-1',
      justify: 'start',
    },
  ];

  const adminMenu: ButtonExtendedProps[] = [
    {
      label: <Box alignSelf="center">Admin</Box>,
      onClick: () => navigate('/admin'),
      icon: (
        <Box pad="small">
          <UserAdmin />
        </Box>
      ),
      color: 'accent-1',
      justify: 'start',
      active: location.pathname === '/admin',
    },
  ];

  const menuItems = userStore.user?.is_admin ? [adminMenu, userMenu] : [userMenu];

  return (
    <Box pad="small">
      <Menu
        justifyContent="center"
        icon={
          <Box pad="small" align="center">
            <Avatar background="dark-4">
              {userStore.user?.first_name?.substring(0, 1)}
              {userStore.user?.last_name?.substring(0, 1)}
            </Avatar>
            <Text alignSelf="center" color="white" margin={{ top: 'small' }}>
              {`${userStore.user?.first_name} ${userStore.user?.last_name}`}
            </Text>
          </Box>
        }
        items={menuItems}
      ></Menu>
    </Box>
  );
});
