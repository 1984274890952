import { Box, FormField } from 'grommet';
import { Bike } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel, Selector } from '..';
import { useProjectStore, usePropStore } from '../../../context';
import { Prop, PropType, Role, TScriptId } from '/lib/api';

export interface IPropPanelProps {
  scriptId?: TScriptId;
  roles: Role[];
}

export const PropPanel = observer(({ scriptId, roles }: IPropPanelProps) => {
  const [props, setProps] = useState<Prop[]>([]);
  const propStore = usePropStore();
  const projectStore = useProjectStore();
  useEffect(() => {
    if (!scriptId) return;
    const getProps = async () => {
      const props = await propStore.getPropsByScriptId(scriptId);
      props && setProps(props);
    };
    getProps();
  }, [scriptId]);

  const newProp = async () => {
    if (!scriptId || !projectStore.currentProject?.id) return;
    const propRequest = {
      name: 'New Prop',
      type: PropType.AUTOMOBILE,
      scriptIds: [scriptId],
      projectId: projectStore.currentProject.id,
    };
    const prop = await propStore.createProp(propRequest);
    props && prop && setProps([...props, prop]);
  };

  const deleteProps = async (ids: string[]) => {
    ids.forEach(async (id) => await propStore.deleteProp(id));
    setProps(props.filter((r) => !ids.includes(r.id)));
  };

  const updateProp = async (update: Prop, index: number) => {
    const prop = await propStore.updateProp(update);
    if (!prop) return;
    const newProps = [...props];
    newProps[index] = prop;
    setProps(newProps);
  };

  return (
    <Panel
      items={props}
      newItem={newProp}
      deleteItems={deleteProps}
      updateItem={updateProp}
      label="Prop"
      labelPlural="Props"
      addIcon={<Bike />}
      types={[
        {
          label: 'Prop Type',
          key: 'type',
          values: Object.values(PropType),
        },
      ]}
    >
      {props.map((prop, index) => (
        <Box pad={{ horizontal: 'small' }} key={prop.id}>
          <FormField label="Role/Owner">
            <Selector
              value={prop.owner?.id || ''}
              key="role"
              options={roles}
              onChange={(value: string) =>
                updateProp({ ...props[index], owner: roles.find((r) => r.id === value) }, index)
              }
              size="small"
            />
          </FormField>
        </Box>
      ))}
    </Panel>
  );
});
