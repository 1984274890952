/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Estimate Statuses
 */
export enum EstimateStatus {
    DRAFT = 'Draft',
    FINAL = 'Final',
}
