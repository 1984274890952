/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Location Type
 *
 * "Nearby" is a location where you return to the reporting site at EOD
 * "Distant" is a location that requires an overnight stay
 */
export enum LocationType {
    STUDIO = 'Studio',
    NEARBY = 'Nearby',
    DISTANT = 'Distant',
    AIRPORT = 'Airport',
}
