import React from 'react';
import { Box } from 'grommet';
import { ChatMessage } from './ChatMessage';
import { Message } from '/src/types';
export interface IMessageListProps {
  messages: Message[];
  messageListRef: React.RefObject<HTMLDivElement>;
  gridArea?: string;
}

export const MessageList = ({ messages, messageListRef, gridArea }: IMessageListProps) => (
  <Box
    round="medium"
    background="white"
    border={{ color: '#d9d9e3' }}
    justify="start"
    alignContent="start"
    gridArea={gridArea}
    id="messages-list"
    direction="column"
    flex="grow"
  >
    <Box pad="small" margin="small" ref={messageListRef} overflow={{ vertical: 'auto' }}>
      {messages.map((message, index) => (
        <ChatMessage message={message} index={index} key={`chatMessage-${index}`} />
      ))}
    </Box>
  </Box>
);
