import { StorageProperties } from '/src/types';

class Storage {
  get(key: StorageProperties, defaultValue = '') {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(key);
      if (value) return JSON.parse(value);
      return defaultValue;
    }
    return false;
  }

  set(key: StorageProperties, value: string | object) {
    if (typeof window !== 'undefined') {
      const valueToStr = JSON.stringify(value || '');
      localStorage.setItem(key, valueToStr);
    }
  }

  remove(key: StorageProperties) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  }

  removeAll() {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
  }

  /* set token */
  setToken(value: string) {
    this.set(StorageProperties.SessionJwt, value);
  }

  /* get token */
  getToken() {
    return this.get(StorageProperties.SessionJwt);
  }


  /* set theme */
  setTheme(value: string) {
    this.set(StorageProperties.Theme, value);
  }

  /* get theme */
  getTheme() {
    return this.get(StorageProperties.Theme, 'light');
  }

  /* remove sign in info */
  removeLogInInfo() {
    this.remove(StorageProperties.SessionJwt);
    this.remove(StorageProperties.SessionUser);
  }
}

const storage = new Storage();

export default storage;
