import { Accordion, AccordionPanel, Box, Tab, Tabs, Text } from 'grommet';
import { Link, User as UserIcon } from 'grommet-icons';
import TalonLogo from 'jsx:/public/images/talon-ai-logo.svg';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Message } from '/src/types';

export interface IChatMessageProps {
  message: Message;
  index: number;
}

export const ChatMessage = ({ message, index }: IChatMessageProps) => {
  const isUserMsg = message.type === 'userMessage';

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Box
      key={`chatMessage-${index}`}
      direction="row"
      pad="small"
      flex="grow"
      border={{ color: '#d9d9e3' }}
      background={isUserMsg ? 'light-1' : 'white'}
      round="small"
      margin="small"
    >
      <Box align="center" justify="center" margin="small" height="40px" width="40px">
        {isUserMsg ? <UserIcon /> : <TalonLogo />}
      </Box>
      <Box direction="column" pad="small">
        <Box pad="small" margin="small" overflow="wrap">
          <Text>
            {message.message.split('\n').map((line, index) => (
              <Box key={index}>
                {line}
                <br />
              </Box>
            ))}
          </Text>
        </Box>
        {!!message.sourceDocs?.length && (
          <Accordion key={`sourceDocsAccordion-${index}`}>
            <AccordionPanel label="Sources">
              <Tabs alignControls="start">
                {message.sourceDocs.map((doc, docIndex) => (
                  <Tab title={docIndex + 1} key={docIndex}>
                    <Text size="small">
                      (Excerpt from {doc.metadata.source})
                      <Link
                        onClick={() => openLink(doc.metadata.url)}
                        size="medium"
                        color="brand"
                        style={{ cursor: 'pointer', marginLeft: '10px', marginRight: 'auto' }}
                      />
                    </Text>
                    <ReactMarkdown linkTarget="_blank">{doc.pageContent}</ReactMarkdown>
                  </Tab>
                ))}
              </Tabs>
            </AccordionPanel>
          </Accordion>
        )}
      </Box>
    </Box>
  );
};
