/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * SessionActivity Type
 */
export enum SessionActivityType {
    WORK_TIME = 'Work Time',
    MEAL_BREAKFAST = 'Meal - Breakfast',
    MEAL_LUNCH = 'Meal - Lunch',
    MEAL_DINNER = 'Meal - Dinner',
    FITTING = 'Fitting',
    WARDROBE_CHANGE = 'Wardrobe Change',
    HAIR_AND_MAKEUP = 'Hair And Makeup',
    TRAVEL_TO_LOCATION = 'Travel To Location',
    TRAVEL_FROM_LOCATION = 'Travel From Location',
    AUDITION = 'Audition',
    REHEARSAL = 'Rehearsal',
    LINE_MEMORIZATION = 'Line Memorization',
    AUDITION_SCRATCH_TRACK = 'Audition Scratch Track',
    DEMO = 'Demo',
    RECORDING_SESSION = 'Recording Session',
    VOICE_OVER = 'Voice Over',
    AFM_RECORDING_SESSION = 'AFM Recording Session',
    CREATIVE_SESSION = 'Creative Session',
    CREATIVE_AUDITION = 'Creative Audition',
}
