/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateRoleGroupRequest } from '../models/TCreateRoleGroupRequest';
import type { TCreateRoleGroupResponse } from '../models/TCreateRoleGroupResponse';
import type { TGetRoleGroupResponse } from '../models/TGetRoleGroupResponse';
import type { TListRoleGroupsResponse } from '../models/TListRoleGroupsResponse';
import type { TRoleGroupId } from '../models/TRoleGroupId';
import type { TUpdateRoleGroupRequest } from '../models/TUpdateRoleGroupRequest';
import type { TUpdateRoleGroupResponse } from '../models/TUpdateRoleGroupResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleGroupService {

    /**
     * Get RoleGroup
     * @param id
     * @param relations
     * @returns TGetRoleGroupResponse TGetRoleGroupResponse
     * @throws ApiError
     */
    public static getRoleGroup(
        id: TRoleGroupId,
        relations?: string,
    ): CancelablePromise<TGetRoleGroupResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role-groups/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update RoleGroup
     * @param id
     * @param requestBody
     * @returns TUpdateRoleGroupResponse TUpdateRoleGroupResponse
     * @throws ApiError
     */
    public static updateRoleGroup(
        id: TRoleGroupId,
        requestBody: TUpdateRoleGroupRequest,
    ): CancelablePromise<TUpdateRoleGroupResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/role-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete RoleGroup
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteRoleGroup(
        id: TRoleGroupId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/role-groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List RoleGroups
     * @param scriptId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListRoleGroupsResponse TListRoleGroupsResponse
     * @throws ApiError
     */
    public static listRoleGroups(
        scriptId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListRoleGroupsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/role-groups',
            query: {
                'scriptId': scriptId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create RoleGroup
     * @param requestBody
     * @returns TCreateRoleGroupResponse TCreateRoleGroupResponse
     * @throws ApiError
     */
    public static createRoleGroup(
        requestBody: TCreateRoleGroupRequest,
    ): CancelablePromise<TCreateRoleGroupResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/role-groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
