/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateTagsRequest } from '../models/TCreateTagsRequest';
import type { TCreateTagsResponse } from '../models/TCreateTagsResponse';
import type { TGetTagsResponse } from '../models/TGetTagsResponse';
import type { TListTagsResponse } from '../models/TListTagsResponse';
import type { TTagsId } from '../models/TTagsId';
import type { TUpdateTagsRequest } from '../models/TUpdateTagsRequest';
import type { TUpdateTagsResponse } from '../models/TUpdateTagsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagsService {

    /**
     * Get Tags
     * @param id
     * @param relations
     * @returns TGetTagsResponse TGetTagsResponse
     * @throws ApiError
     */
    public static getTags(
        id: TTagsId,
        relations?: string,
    ): CancelablePromise<TGetTagsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Tags
     * @param id
     * @param requestBody
     * @returns TUpdateTagsResponse TUpdateTagsResponse
     * @throws ApiError
     */
    public static updateTags(
        id: TTagsId,
        requestBody: TUpdateTagsRequest,
    ): CancelablePromise<TUpdateTagsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Tags
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteTags(
        id: TTagsId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Tags
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListTagsResponse TListTagsResponse
     * @throws ApiError
     */
    public static listTags(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListTagsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Tags
     * @param requestBody
     * @returns TCreateTagsResponse TCreateTagsResponse
     * @throws ApiError
     */
    public static createTags(
        requestBody: TCreateTagsRequest,
    ): CancelablePromise<TCreateTagsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
