/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Edit Types
 *
 * Part I, Section 26
 */
export enum EditType {
    SHORTER_OR_LONGER_VERSION = 'Shorter Or Longer Version',
    PERMITTED_CHANGES = 'Permitted Changes',
    PAID_EDITS = 'Paid Edits',
    ADDRESSABLE_EDITS = 'Addressable Edits',
}
